import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  Box,
  Stack,
  IconButton,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { formatDate2 } from '../../../utils/commonFunc';

const TABLE_HEAD = [
  { id: 'userId', label: 'User Id', alignRight: false },
  { id: 'invoiceNo', label: 'Invoice No', alignRight: false },
  {
    id: 'holidaySavingsAmount',
    label: 'Holiday Savings Amount',
    alignRight: false,
  },
  { id: 'createdDate', label: 'Created Date', alignRight: false },
];

const HolidayDetails = () => {
  const { holidayId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [holidaySavings, setHolidaySavings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');
  const [searchQuery, setSearchQuery] = useState('');

  const getHolidaySavings = async () => {
    setLoading(true);
    try {
      const URL = `/admin/invoice/getall-holidaysavings-amount?userId=${holidayId}& PageNumber=${
        page + 1
      }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`;
      const result = await axios.get(URL);
      if (result?.status === 200) {
        setLoading(false);
        setHolidaySavings(result?.data?.response);
        setTotalCount(result?.data?.totalCount);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    getHolidaySavings();
  }, [page, rowsPerPage, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      holidaySavings,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      holidaySavings
    );
    setHolidaySavings(filteredRows);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Page title="Holiday Savings">
      {loading && <Loader />}

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={() => navigate(-1)}>
            <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
            Holiday Savings Transaction
          </Typography>
        </Stack>
      </Stack>

      <Container>
        <TableContainer component={Paper}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <ListToolbar searchQuery={searchQuery} onFilter={handleFilter} />
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              hideCheckboxColumnHeader="true"
            />
            <TableBody>
              {holidaySavings?.length ? (
                holidaySavings?.map((item) => {
                  return (
                    <TableRow hover key={item?.userId}>
                      <TableCell>{item?.userId}</TableCell>
                      <TableCell>{item?.invoiceNumber}</TableCell>
                      <TableCell>{item?.holidaySavingsAmount}</TableCell>
                      <TableCell>{formatDate2(item?.createdDate)} </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className="text-center">
                  <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                    No record found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 250, 500]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </Page>
  );
};

export default HolidayDetails;
