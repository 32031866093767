/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BsFiletypeXml } from 'react-icons/bs';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
// component
import { SearchType } from 'src/components/common/SearchType';
import { useLocation } from 'react-router-dom';
import Iconify from '../../../components/Iconify';
import { monthsData } from '../../../utils/commonFunc';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  searchQuery: PropTypes.string,
  onFilter: PropTypes.func,
  onDelete: PropTypes.func,
  salarySelected: PropTypes.number,
  onMoveSalary: PropTypes.func,
  onUndoSalary: PropTypes.func,
  onDeleteSalary: PropTypes.func,
  selectedSalaryNestedTabValue: PropTypes.string,
  isAddFileBtn: PropTypes.string,
  isAddCreateBtn: PropTypes.string,
  onAddCreateBtn: PropTypes.func,
  onAddTaxFile: PropTypes.func,
  settoggleModal: PropTypes.func,
  setIsToggleXml: PropTypes.func,
  isAddCreateFile: PropTypes.bool,
  isSyncBtn: PropTypes.bool,
  handleOnSync: PropTypes.func,
};

export default function ListToolbar({
  numSelected,
  searchQuery,
  onFilter,
  onDelete,
  onSendEmail,
  hideDeleteButton,
  hideEmailButton,
  salarySelected,
  onMoveSalary,
  onUndoSalary,
  onDeleteSalary,
  selectedSalaryNestedTabValue,
  isAddFileBtn,
  isAddCreateBtn,
  onAddCreateBtn,
  onAddTaxFile,
  settoggleModal,
  dropOptions,
  searchDropValue,
  setSearchDropValue,
  setIsToggleXml,
  isAddCreateFile,
  isSyncBtn,
  handleOnSync,
  syncSelected,
  getYears,
  handleChangeDate,
  year,
  month,
  handleClearFilter,
}) {
  const location = useLocation();

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <div className="d-flex align-items-center">
          <SearchStyle
            className="search__bar"
            value={searchQuery || ''}
            onChange={onFilter}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />

          {!isAddCreateFile && !isAddFileBtn &&
           (location.pathname !== '/dashboard/notifications' && location.pathname !== '/dashboard/view-logs' && (
            <SearchType
              dropOptions={dropOptions}
              searchDropValue={searchDropValue}
              setSearchDropValue={setSearchDropValue}
            />
          ))}
          {isAddCreateFile && (
            <SearchType
              dropOptions={dropOptions}
              searchDropValue={searchDropValue}
              setSearchDropValue={setSearchDropValue}
            />
          )}

          {isAddCreateBtn && (
            <div className="ml-4 d-flex align-items-center">
              <FormControl sx={{ minWidth: 120 }} size="small" style={{ marginTop: '7px' }}>
                <InputLabel id="demo-select-small-label">Months</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={month}
                  label="Months"
                  onChange={(e) => handleChangeDate(e, 'month')}
                >
                  <option disabled value="">
                    {'Select month'}
                  </option>
                  {monthsData?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 120 }} size="small" style={{ marginTop: '7px', marginLeft: "4px" }}>
                <InputLabel id="demo-select-small-label">Year</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={year}
                  onChange={(e) => handleChangeDate(e, 'year')}
                >
                  {getYears &&
                    getYears.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button variant="outlined" className="ml-2" onClick={() => handleClearFilter()}>
                Clear Filters{' '}
              </Button>
            </div>
          )}
        </div>
      )}

      {numSelected > 0 ? (
        <div>
          {!hideEmailButton && (
            <Tooltip title="Send Email">
              <IconButton onClick={() => onSendEmail()}>
                <Iconify sx={{ mt: 1, width: 27, height: 27 }} icon="material-symbols:outgoing-mail" />
              </IconButton>
            </Tooltip>
          )}
          {!hideDeleteButton && (
            <Tooltip title="Delete">
              <IconButton onClick={() => onDelete()}>
                <Iconify icon="eva:trash-2-fill" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ) : (
        ''
      )}

      {salarySelected > 0 ? (
        <div>
          {(selectedSalaryNestedTabValue === '2' || selectedSalaryNestedTabValue === '3') && (
            <>
              <Tooltip title="Generate PDF">
                <Button onClick={() => settoggleModal(true)}>
                  <PictureAsPdfIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Generate XML">
                <Button onClick={() => setIsToggleXml(true)}>
                  <BsFiletypeXml style={{ fontSize: 20 }} />
                </Button>
              </Tooltip>
            </>
          )}

          {!hideDeleteButton && selectedSalaryNestedTabValue !== '1' && (
            <Tooltip
              title={
                selectedSalaryNestedTabValue === '3'
                  ? 'Move to Checked'
                  : selectedSalaryNestedTabValue === '2'
                  ? 'Move to Unpaid'
                  : ''
              }
            >
              <IconButton onClick={() => onUndoSalary()}>
                <Iconify icon="material-symbols:undo" />
              </IconButton>
            </Tooltip>
          )}

          {!hideEmailButton && (
            <Tooltip
              title={
                selectedSalaryNestedTabValue === '1'
                  ? 'Move to checked'
                  : selectedSalaryNestedTabValue === '2'
                  ? 'Generate Bank File'
                  : 'Generate Bank File'
              }
            >
              <IconButton onClick={() => onMoveSalary()}>
                <Iconify
                  sx={{ mt: 1, width: 27, height: 27 }}
                  icon="fluent-mdl2:generate"
                  style={{ height: '21px', marginBottom: '3px', marginRight: '5px' }}
                />
              </IconButton>
            </Tooltip>
          )}
          {!hideDeleteButton && selectedSalaryNestedTabValue === '1' && (
            <Tooltip title="Delete">
              <IconButton onClick={() => onDeleteSalary()}>
                <Iconify icon="eva:trash-2-fill" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ) : (
        <>
          {isAddCreateBtn && (
            <Button variant="outlined" onClick={() => onAddCreateBtn()}>
              {'Create Salary'}
            </Button>
          )}
        </>
      )}

      {isAddFileBtn ? (
        <Button variant="outlined" onClick={() => onAddTaxFile()}>
          {'Create tax file'}
        </Button>
      ) : (
        ''
      )}

      {isSyncBtn ? (
        <Button variant="outlined" disabled={!syncSelected.length > 0} onClick={() => handleOnSync()}>
          {'Sync to Fortnox'}
        </Button>
      ) : (
        ''
      )}
    </RootStyle>
  );
}
