export const LOCALES = {
  EN_US: 'en-US',
  SV_SE: 'sv-SE',
}

export const NEW_ARTICLES = {
  MILEAGE: "mileage",
  ALLOWANCE_IN: "allowance_in",
  ALLOWANCE_OUT: "allowance_out",
  TRAVEL:"travel",
  MATERIAL_VAT: "material_vat"
}

export const AMOUNT_PER_UNIT = {
  MILEAGE: 2.50,
  ALLOWANCE_IN_HALF_DAY: 145,
  ALLOWANCE_IN_FULL_DAY: 290,

}

export const ARTICLE_NUMBERS = {
  MILEAGE_TAX_FREE: "87",
  MILEAGE_WITH_TAX: "88",
  ALLOWANCE_IN_TAX_FREE_HALF_DAY: "83",
  ALLOWANCE_IN_WITH_TAX_HALF_DAY: "84",
  ALLOWANCE_IN_TAX_FREE_FULL_DAY: "85",
  ALLOWANCE_IN_WITH_TAX_FULL_DAY: "86",
  ALLOWANCE_OUT_TAX_FREE: "81",
  ALLOWANCE_OUT_WITH_TAX: "82",
  TRAVEL:"91",
  MATERIAL_VAT_TAX_FREE: "2",
  MATERIAL_VAT_WITH_TAX: "1",
}