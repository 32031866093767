/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import PdfPreview from './PdfPreview';
import logo from '../assets/img/fakt-logo_360.png';
import { englishTranslations, HourlyWages } from '../utils/stringUtils';
import { diffInDates, formatInvoiceDate, getArticleNumber } from '../utils/commonFunc';
import { Button, FormControl, NativeSelect } from '@mui/material';

import { toast } from 'react-toastify';
import { AMOUNT_PER_UNIT, NEW_ARTICLES } from 'src/utils/constants';

const ShowInvoiceDialog = ({
  show,
  file,
  onClose,
  selectedFreelancers = [],
  customer,
  formValues,
  calculatedAmounts,
  currency,
  user,
  otherArticleValues,
  createdDate,
  totalHours,
  getPdfUrl,
  currencyGlobalValue,
}) => {
  const [preferredLang, setPreferredLang] = useState('en');

  const totalworkingHours = totalHours?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const handleClose = () => {
    handleLanguageChange({ target: { value: 'en' } });

    if (onClose) onClose();
  };

  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    setPreferredLang(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const date = new Date();
  let dateStr = `${date.getFullYear()} -`;
  dateStr += date.getMonth() + 1 > 9 ? date.getMonth() + 1 : 0 + (date.getMonth() + 1);
  dateStr += date.getDate() > 9 ? -+date.getDate() : -+0 + date.getDate();

  let mergeArticleNo92;
  if (formValues?.invoiceHours) {
    const cloneInvoiceHours = [...formValues?.invoiceHours];

    const mergeTotalAmount =
      cloneInvoiceHours[0] &&
      Object?.values(cloneInvoiceHours[0]).map((item, index) => {
        return {
          ...item,
          totalAmount: parseFloat(item.amount) * parseFloat(item.units),
          calculatedAmounts: parseFloat(item.amount) * parseFloat(item.units),
          otherArticleSum: 0,
        };
      });

    const otherArticleSum = mergeTotalAmount?.reduce((acc, curr) => {
      if (curr.article?.articleNumber !== HourlyWages) {
        acc += parseFloat(curr.amount);
      }
      return acc;
    }, 0);

    mergeArticleNo92 = mergeTotalAmount?.reduce((result, item) => {
      if (item.article?.articleNumber === HourlyWages) {
        const existingItem = result.find((i) => i.article.articleNumber === HourlyWages);
        if (existingItem) {
          existingItem.units = parseFloat(existingItem.units) + parseFloat(item.units);
          existingItem.amount = parseFloat(existingItem.amount) + parseFloat(item.amount);
          existingItem.totalAmount += item.amount * item.units;
          existingItem.calculatedAmounts = calculatedAmounts?.amount;
          existingItem.otherArticleSum = otherArticleSum;
        } else {
          result.push({ ...item });
        }
      } else {
        result.push({ ...item });
      }
      return result;
    }, []);
  }

  const translations = {
    en: {
      "Mileage Report": "Mileage Report",
      "Travel Expenses as per attached PDF":
        "Travel Expenses as per attached PDF",
      "Expenses for materials and tools as per attached PDF":
        "Expenses for materials and tools as per attached PDF",
      "Allowance outside Sweden": "Allowance outside Sweden",
      "Allowance in Sweden": "Allowance in Sweden",
    },
    de: {
      "Mileage Report": "Körjournal",
      "Travel Expenses as per attached PDF": "Resekostnader enligt bifogad pdf",
      "Expenses for materials and tools as per attached PDF":
        "Utlägg för material och verktyg enligt bifogad pdf",
      "Allowance outside Sweden": "Traktamente utanför Sverige",
      "Allowance in Sweden": "Traktamente i Sverige",
    },
  };

  const translateLabel = (key) => {
    return translations[preferredLang]?.[key] || translations.de[key] || key;
  };

  const getDatesForItem = (item) => {
    if (!item || !item.key || !item.data) return "";
  
    switch (item.key) {
      case NEW_ARTICLES.MILEAGE:
        return item?.data?.travelDate || item?.data?.workingDate?.split("T")[0];
  
      case NEW_ARTICLES.ALLOWANCE_OUT:
        return item?.data?.tripStartedDateOs && item?.data?.tripEndedDateOs
          ? `${item?.data?.tripStartedDateOs} - ${item?.data?.tripEndedDateOs}`
          : item?.data?.tripStartedDate && item?.data?.tripEndedDate
          ? `${item?.data?.tripStartedDate.split("T")[0]} - ${item?.data?.tripEndedDate.split("T")[0]}`
          : "";
  
      case NEW_ARTICLES.ALLOWANCE_IN:
        return item?.data?.tripStartedDateIs && item?.data?.tripEndedDateIs
          ? `${item?.data?.tripStartedDateIs} - ${item?.data?.tripEndedDateIs}`
          : item?.data?.tripStartedDate && item?.data?.tripEndedDate
          ? `${item?.data?.tripStartedDate.split("T")[0]} - ${item?.data?.tripEndedDate.split("T")[0]}`
          : "";
  
      case NEW_ARTICLES.TRAVEL:
        return item?.data?.travelExpDate || item?.data?.workingDate?.split("T")[0];
  
      case NEW_ARTICLES.MATERIAL_VAT:
        return item?.data?.dateOfPurchase || item?.data?.workingDate?.split("T")[0];
  
      default:
        return "";
    }
  };
  
  
  const getValueForItem = (item) => {
    switch (item.key) {
      case NEW_ARTICLES.MILEAGE:
        return item?.data?.distance;
      case NEW_ARTICLES.TRAVEL:
      case NEW_ARTICLES.MATERIAL_VAT:
        return 1;
      case NEW_ARTICLES.ALLOWANCE_OUT:
      case NEW_ARTICLES.ALLOWANCE_IN:
        return diffInDates(
          item?.key,
          item?.data?.tripEndedDateIs || item?.data?.tripEndedDate,
          item?.data?.tripStartedDateIs || item?.data?.tripStartedDate,
          item?.data?.tripEndedDateOs || item?.data?.tripEndedDate,
          item?.data?.tripStartedDateOs || item?.data?.tripStartedDate
        );
      default:
        return "";
    }
  };
  
  const getUnitForItem = (item, preferredLang) => {
    switch (item.key) {
      case NEW_ARTICLES.MILEAGE:
        return "km";
      case NEW_ARTICLES.ALLOWANCE_OUT:
      case NEW_ARTICLES.ALLOWANCE_IN:
        return preferredLang === "en" ? "day" : "dag";
      default:
        return preferredLang === "en" ? "pcs" : "st";
    }
  };
  
  const getAmountForItem = (item, currencyGlobalValue) => {
  
    switch (item.key) {
      case NEW_ARTICLES.MILEAGE: {
        const mileageValue = Number(AMOUNT_PER_UNIT.MILEAGE) / Number(currencyGlobalValue);
        return mileageValue.toFixed(2);
      }
  
      case NEW_ARTICLES.TRAVEL:
      case NEW_ARTICLES.MATERIAL_VAT: {
        const travelOrMaterialValue = 
 Number(item?.data?.amountInSek || item?.value) / Number(currencyGlobalValue);
        return travelOrMaterialValue.toFixed(2);
      }
  
      case NEW_ARTICLES.ALLOWANCE_IN: {
        const allowanceInValue =
        item?.data?.isHalfDay ? (item?.data?.isHalfDay === "1"
            ? Number(AMOUNT_PER_UNIT.ALLOWANCE_IN_HALF_DAY) / Number(currencyGlobalValue)
            : Number(AMOUNT_PER_UNIT.ALLOWANCE_IN_FULL_DAY) / Number(currencyGlobalValue)) :
    Number(item?.data?.allowancePerDay || item?.data?.amountPerUnitSek) / Number(currencyGlobalValue)
        return allowanceInValue.toFixed(2);
      }
  
      case NEW_ARTICLES.ALLOWANCE_OUT: {
        const allowanceOutValue = Number(item?.data?.allowancePerDay || item?.data?.amountPerUnitSek
        ) / Number(currencyGlobalValue);
        return allowanceOutValue.toFixed(2);
      }
  
      default:
        return "";
    }
  };
  

  const getPriceForItem = (item) => {
    const amount =  Number(item?.data?.amount) || Number(item?.valueInSelectedCurrency);
    return amount.toFixed(2);
  };
  
  const getTotalForItem = (item, vat) => {
    const amount =   Number(item?.data?.amount) || Number(item?.valueInSelectedCurrency) ;
  
    const vatRate = Number(item?.data?.vat != null ? item?.data?.vat : vat || 0); // Default to 0 if vat is undefined
    const vatValue = (amount * vatRate) / 100;
  
    const total = amount + vatValue;
    return total.toFixed(2);
  };

  function getUnitInEnglish(unit) {
    return englishTranslations[unit] || unit?.toLowerCase();
  }
  
  function getUnitInSwedish(unit) {
    switch (unit) {
      case "m":
        return "månad"; // Month
      case "a":
        return "enl. ök"; // According to agreement
      case "h":
        return "tim"; // Hour
      case "d":
        return "dag"; // Day
      case "w":
        return "vecka"; // Week
      default:
        return unit?.toLowerCase();
    }
  }
  

  return (
    <Modal className="invoice-dialog invoice-modal" size="lg" show={show} onHide={handleClose} scrollable>
      <Modal.Header>
        <h5 className="modal-title" id="exampleModalLabel">
          {t('Select Your Invoice Language')}
        </h5>

        <div className="ml-4">
          <FormControl>
            <NativeSelect
              defaultValue={30}
              inputProps={{
                name: 'lang',
                id: 'uncontrolled-native',
              }}
              value={preferredLang}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="de">Swedish</option>
            </NativeSelect>
          </FormControl>
        </div>

        <AiOutlineClose className="ml-auto cursor-pointer" size={24} onClick={handleClose} />
      </Modal.Header>

      <Modal.Body className="p-3">
        <div id="invoice-dialog-div">
          <div className="p-3">
            <div className="logo-info ">
              <div className="">
                <div className="heading d-flex align-items-center justify-content-between px-3">
                  <div className="logo-pdf">
                    <img src={logo} alt="logo" className="logo" />
                  </div>
                  <div className="date-text">
                    <h4 className="mb-0">
                      {t('Preliminary Invoice')} <span>{formatInvoiceDate(createdDate, false)}</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="px-3 py-2 mb-3 flex-change">
                  <h3 className="text-right">{customer?.name}</h3>
                  <p className="text-right m-0">{customer?.address}</p>
                  <p className="text-right m-0">{customer?.phoneNumber}</p>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <div className="customer d-flex align-items-center justify-content-between">
                  <div className="first">
                    <div className="inner-group">
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Customer no')}:</h4>
                        <p className="m-0">{customer?.customerId}</p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Invoice No')}: {t('Not created yet')}{' '}
                        </h4>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Your reference')}:</h4>
                        <p className="m-0">{customer?.reference}</p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Your VAT number')}:</h4>
                        <p className="m-0">{customer?.vatNumber}</p>
                      </div>
                    </div>
                  </div>
                  <div className="second">
                    <div className="inner-group">
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Our reference')}:</h4>
                        <p className="m-0">
                          {user?.firstName} {user?.lastName}
                        </p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">{t('Payment terms')}:</h4>
                        <p className="m-0">
                          {customer?.paymentTerm} {t('Days')}
                        </p>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Due date')}: {formatInvoiceDate(createdDate, customer?.paymentTerm)}{' '}
                        </h4>
                      </div>
                      <div className="customer-data d-flex align-items-center">
                        <h4 className="m-0">
                          {t('Invoice date')}: {formatInvoiceDate(createdDate, false)}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col overflow-auto show-invoice-wrapper">
                <table className="table">
                  <tr className="border-invoice">
                    {!formValues?.isFixed && <th>{t('Article No.')}</th>}
                    <th>{t('Name')}</th>
                    {!formValues?.isFixed && (
                      <>
                        <th>{t('Quantity')}</th>
                        <th>{t('Unit')}</th>
                        <th>{t('Price per unit')}</th>
                      </>
                    )}
                    <th>{t('Total')}</th>
                    <th>{t("VAT")} %</th>
                    {!formValues?.isFixed && (
                      <th>
                        {t("Amount inc. VAT")}
                      </th>
                    )}
                  </tr>
                  {formValues?.isFixed ||
                  (formValues.showDates !== 'true' && selectedFreelancers && selectedFreelancers?.length === 0)
                  ? mergeArticleNo92?.map((invoiceItem, index) => {
                        return (
                          <tr key={index}>
                            {!formValues.isFixed && <td className="m-0">{invoiceItem?.article?.articleNumber}</td>}
                            <td className="m-0">{t(invoiceItem?.article?.description)}</td>
                            {!formValues.isFixed && (
                              <>
                                <td className="m-0">
                                  <span
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={t(invoiceItem?.article?.description)}
                                    style={{ cursor: 'default' }}
                                  >
                                    {invoiceItem?.article?.articleNumber === HourlyWages ? invoiceItem?.units : 1}
                                  </span>
                                </td>
                                <td className="m-0 cursor-pointer" title={t(invoiceItem?.article.description)}>
                                  {preferredLang === "en" ?  englishTranslations[`${invoiceItem?.article?.unit}`] : (invoiceItem?.article.unit).toLowerCase() }
                                </td>

                                <td className="m-0">
                                  {formValues.isFixed
                                    ? Number.isInteger(
                                        (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                          invoiceItem.units
                                      )
                                      ? (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                        invoiceItem.units
                                      : parseFloat(
                                          (invoiceItem?.calculatedAmounts - invoiceItem?.otherArticleSum) /
                                            invoiceItem.units
                                        )?.toFixed(2)
                                    : Number.isInteger(invoiceItem.totalAmount / invoiceItem.units)
                                    ? invoiceItem.totalAmount / invoiceItem.units
                                    : parseFloat(invoiceItem.totalAmount / invoiceItem.units)?.toFixed(2)}
                                </td>
                              </>
                            )}

                            <td className="m-0">
                              {formValues?.isFixed ? t('Fixed Price') : ''}{' '}
                              {!formValues?.isFixed &&
                                invoiceItem?.article &&
                                invoiceItem?.article?.unit &&
                                invoiceItem?.totalAmount?.toFixed(2)}
                            </td>
                              <td> {formValues?.vat}</td>
                              <td>
                                {!formValues?.isFixed &&
                                  invoiceItem?.article &&
                                  invoiceItem?.article?.unit &&
                                  invoiceItem?.amount !== "" &&
                                  (
                                    parseFloat(invoiceItem?.amount) *
                                      parseFloat(invoiceItem?.units) +
                                    (parseFloat(invoiceItem?.amount) *
                                      parseFloat(invoiceItem?.units) *
                                      formValues?.vat) /
                                      100
                                  )?.toFixed(2)}
                              </td>
                          </tr>
                        );
                      })
                    : formValues?.invoiceHours?.map((allIndex, allIndexKey) =>
                        Object.keys(allIndex)?.map((date, index) => {
                          const userFirstName = user?.firstName;
                          const userLastName = user?.lastName;
                          return (
                            <tr key={index}>
                              {!formValues.isFixed && <td className="m-0">{allIndex[date]?.article?.articleNumber}</td>}

                              <td className="m-0">
                                {preferredLang === "en" ?  (englishTranslations[`${allIndex[date]?.article?.description}`] || t(allIndex[date]?.article?.description)) : t(allIndex[date]?.article?.description)}{' '}
                                {!formValues?.isFixed
                                  ? formValues?.showDates === 'true' &&
                                    (() => {
                                      const [year, month, day] = date?.split('-');
                                      return `${year}-${month}-${day} `;
                                    })()
                                  : ''}{' '}
                                {allIndexKey === 0
                                  ? `(${userFirstName} ${userLastName})`
                                  : formValues?.freelancer?.[allIndexKey - 1]?.signUpUser
                                  ? `(${formValues.freelancer[allIndexKey - 1].signUpUser.firstName} ${
                                      formValues.freelancer[allIndexKey - 1].signUpUser.lastName
                                    })`
                                  : ''}
                              </td>
                              {!formValues?.isFixed && (
                                <>
                                  <td className="m-0">
                                    <span
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={t(allIndex[date]?.article?.description)}
                                      style={{ cursor: 'default' }}
                                    >
                                      {allIndex[date]?.article?.articleNumber === HourlyWages
                                        ? allIndex[date]?.units
                                        : 1}
                                    </span>
                                  </td>
                                  <td 
  className="m-0 cursor-pointer" 
  title={t(allIndex[date]?.article?.description)}
>
  {preferredLang === "en" 
    ? getUnitInEnglish(allIndex[date]?.article?.unit) 
    : getUnitInSwedish(allIndex[date]?.article?.unit)
  }
</td>

                                  <td className="m-0">
                                    {allIndex[date]?.amount !== '' ? Number(allIndex[date]?.amount)?.toFixed(2) : 0}
                                  </td>
                                </>
                              )}
                              <td className="m-0">
                                {formValues?.isFixed ? t('Fixed Price') : ''}{' '}
                                {!formValues?.isFixed &&
                                  allIndex[date]?.article &&
                                  allIndex[date]?.article?.unit &&
                                  allIndex[date]?.amount !== '' &&
                                  (parseFloat(allIndex[date]?.amount) * parseFloat(allIndex[date]?.units))?.toFixed(2)}
                                {!formValues?.isFixed &&
                                  allIndex[date]?.article &&
                                  allIndex[date]?.article?.unit &&
                                  allIndex[date]?.amount === '' &&
                                  0 * parseFloat(allIndex[date]?.units)}
                              </td>
                              <td> {formValues?.vat}</td>
                              <td>
                                {!formValues?.isFixed &&
                                  allIndex[date]?.article &&
                                  allIndex[date]?.article?.unit &&
                                  allIndex[date]?.amount !== "" &&
                                  (
                                    parseFloat(allIndex[date]?.amount) *
                                      parseFloat(allIndex[date]?.units) +
                                    (parseFloat(allIndex[date]?.amount) *
                                      parseFloat(allIndex[date]?.units) *
                                      formValues?.vat) /
                                      100
                                  )?.toFixed(2)}
                              </td>
                            </tr>
                          );
                        })
                      )}
                      
{!formValues?.isFixed &&
                    Object.entries(otherArticleValues)?.map(([key, values]) =>
                      values
                    ?.filter(
                      (item) =>
                       (item.data?.isMileageArticleShowInInvoice === "1" &&
                       item.data?.isArticleShowInInvoiceOS === "1" &&
                       item.data?.isArticleShowInInvoiceIS === "1" &&
                       item.data?.isTravelArticleShowInInvoice === "1" &&
                       item.data?.isMaterialArticleShowInInvoice === "1" )
                       ||
                       item.data?.isArticleShownInInvoice === true               
                     
                    )
                        ?.map((item, index) => (
                          
                          <tr key={index}>
                          <td className="m-0">{getArticleNumber(item)}</td>
                          <td className="m-0">
                            {translateLabel(item?.label)}{" "}
                            {formValues?.showDates === "true" ? getDatesForItem(item) : ""}{" "}
                            {values?.[index]?.userId === user?.userId ? ( 
                              <>
                                ({user?.firstName} {user?.lastName})
                              </>
                            ) : values?.[index]?.userId === formValues?.freelancer[key - 1]?.userId ? (
                              <>
                                ({formValues?.freelancer[key - 1]?.signUpUser?.firstName}{' '}
                                {formValues?.freelancer[key - 1]?.signUpUser?.lastName})
                              </>
                            ) : (
                              ' '
                            )}
                          </td>
                          <td className="m-0">
                            <span
                              data-toggle="tooltip"
                              data-placement="top"
                              title={item?.label}
                              style={{ cursor: "default" }}
                            >
                              {getValueForItem(item)}
                            </span>
                          </td>
                          <td
                            className="m-0 cursor-pointer"
                            title={item?.label}
                          >
                            {getUnitForItem(item, preferredLang)}
                          </td>
                          <td className="m-0">
                            {getAmountForItem(item, currencyGlobalValue)}
                          </td>
                          <td className="m-0">
                            {formValues?.isFixed
                              ? preferredLang === "en"
                                ? "Fixed Price"
                                : "Fast pris"
                              : getPriceForItem(item)}
                          </td>
                          <td>
                            {item?.data?.vat != null ? item?.data?.vat : formValues?.vat}
                          </td>
                          <td>
                            {getTotalForItem(item, formValues?.vat)}
                          </td>
                        </tr>                        
                        ))
                    )}
                </table>
              </div>
            </div>
            {!formValues?.isFixed && (
              <div className="d-flex align-items-right justify-content-end">
                <h4 className="m-0">{t('Total working hours:')}&nbsp;</h4>
                <p>{totalworkingHours || 0}</p>
              </div>
            )}

            <div className="row">
              <div className="col">
                <div className=" p-0 description" style={{ wordWrap: 'break-word' }}>
                  <p className="m-0">{formValues?.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="border border-light vat rounded p-2 mb-3">
                  <div className="d-flex mb-3 response-data justify-content-between">
                    <div className="text-center">
                      <h6 className="m-0">{t('Excluding VAT')}</h6>
                      <h6 className="m-0">
                        {Number.isInteger(calculatedAmounts?.amount)
                          ? calculatedAmounts?.amount
                          : parseFloat(calculatedAmounts?.amount)?.toFixed(2)}
                      </h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('VAT')} %</h6>
                      <h6 className="m-0">{formValues?.vat}</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('VAT')}</h6>
                      <h6 className="m-0">{calculatedAmounts?.vat}</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('Round-off')}</h6>
                      <h6 className="m-0">0</h6>
                    </div>
                    <div className="text-center">
                      <h6 className="m-0">{t('Currency')}</h6>
                      <h6 className="m-0">{formValues?.currency}</h6>
                    </div>

                    <div className="text-center">
                      <h6 className="m-0">{t('ROT / RUT')}</h6>
                      <h6 className="m-0">
                        {calculatedAmounts?.rootDeductionAmount} / {calculatedAmounts?.rutDeductionAmount}
                      </h6>
                    </div>

                    <div className="text-center">
                      <h6 className="m-0 font-weight-bold">{t('To pay')}</h6>
                      <h6 className="m-0 font-weight-bold">
                        <span> {currency} </span>{' '}
                        {Number.isInteger(calculatedAmounts?.amountIncludingVat)
                          ? calculatedAmounts?.amountIncludingVat
                          : parseFloat(calculatedAmounts?.amountIncludingVat)?.toFixed(2)}
                      </h6>
                    </div>
                  </div>
                </div>
                <p className="text-center invoice m-0">{t('PLEASE NOTE THAT THIS IS A PRELIMINARY INVOICE')}</p>
              </div>
            </div>
            <div className="bottom-part px-3">
              <h4 className="invoice m-0">{t('INVOZIO LV SIA')}</h4>
              <div className="contact">
                <p className="m-0">010-264 88 10</p>
                <p className="m-0">{t('support@invozio.com')}</p>
              </div>
              <div className="address">
                <p className="m-0">{t('Invozio LV SIA')}</p>
                <p className="m-0">
                  {t('Org. Number')}: <span> 502085-4062</span>
                </p>
                <p className="m-0">
                  {t('Vat Number')}: <span>SE502085406201 </span>
                </p>
              </div>
            </div>
            <h1 className="background-txt mb-0">{t('Preliminaryinvoice')}</h1>
            <PdfPreview
              show={show}
              pdfPreview={getPdfUrl}
              existingPdf={formValues}
              file ={file}
              otherArticleValues={otherArticleValues}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowInvoiceDialog;
