/* eslint-disable prefer-const */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */

import { memo, useEffect, useState, useCallback } from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js`;

const PdfPreview = memo(({ file }) => {
  const [pageCount, setPageCount] = useState(0);

  return (
    <Document
      file={file}
      onLoadError={(err) => console.log("onload err", err)}
      onLoadSuccess={({ numPages }) => setPageCount(numPages)}
    >
      {Array.from(new Array(pageCount), (el, index) => (
        <Page
          key={index}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      ))}
    </Document>
  );
});

const Example = ({
  file,
  pdfPreview,
  existingPdf,
  show,
  otherArticleValues,
}) => {
  const [fileDataList, setFileDataList] = useState([]);
  const [existingDataList, setExistingDataList] = useState([]);
  const [fileParseDone, setFileParseDone] = useState(false);

  const requiredFlags = [
    "isMileagePdfShowToCustomer",
    "isAosPdfShowToCustomer",
    "isAisPdfShowToCustomer",
    "isTravelPdfShowToCustomer",
    "isMaterialPdfShowToCustomer",
  ];

  const fileToString = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  const getFileData = useCallback(async (fileDetails) => {
    try {
      const promises = fileDetails.map(async (file) => {
        let fileToProcess;
  
        if (file instanceof File) {
          const arrayBufferView = new Uint8Array(await file.arrayBuffer());
          fileToProcess = new Blob([arrayBufferView], { type: "application/pdf" });
        } else if (typeof file === 'string') {
          const byteCharacters = atob(file); 
          const byteArrays = new Uint8Array(byteCharacters.length);
  
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArrays[i] = byteCharacters.charCodeAt(i);
          }
  
          fileToProcess = new Blob([byteArrays], { type: "application/pdf" });
        }
  
        const res = await fileToString(fileToProcess);
        return res;
      });
  
      const data = await Promise.all(promises);
      setFileDataList(data);
      setFileParseDone(true);
    } catch (err) {
      setFileParseDone(false);
    }
  }, []);

  const extractFilesFromValues = (values) => {
    const filteredEntries = Object.values(values).flatMap((value) =>
      value?.filter((item) =>
        requiredFlags.every((flag) => item.data?.[flag] === "1") ||
        item.data?.isPdfAttachedToInvoice === true
      ) || []
    );

    const extractedFiles = filteredEntries
      .flatMap(entry => {
        const uploadFile = entry?.data?.uploadFile || entry?.data?.fileContent || entry?.data?.uploadfileForInvoice;
  
        if (typeof uploadFile === "object" && uploadFile !== null) {
          return Object.values(uploadFile);
        } 
         if (typeof uploadFile === "string") {
          return [uploadFile];
        }
        return [];
      })
      .filter(file => file instanceof File || typeof file === "string"); 
  
    return extractedFiles;
  };

  useEffect(() => {
    if (pdfPreview?.length > 0) {
      const validFiles = pdfPreview?.flatMap((FileList) =>
        Array.from(FileList).filter((f) => f !== undefined)
      );
      getFileData(validFiles);
    }
  }, [pdfPreview, getFileData]);

  useEffect(() => {
    if (existingPdf?.invoiceFile && existingPdf?.invoiceFile.length > 0) {
      const filtered = existingPdf?.invoiceFile?.filter((d) => d?.userId);
    }
  }, [existingPdf?.invoiceFile, show]);

  useEffect(() => {
    let extractedFiles = [];
  
    if (otherArticleValues) {
      extractedFiles = extractFilesFromValues(otherArticleValues);
    }
  
    if (file) {
      extractedFiles = [...extractedFiles, ...file.filter(Boolean)];
    }
  
    if (extractedFiles.length > 0) {
      getFileData(extractedFiles);
    }
  }, [otherArticleValues, file, getFileData]);

  return (
    <>
      {fileParseDone
        ? fileDataList.map((fileData, index) => (
            <PdfPreview key={`file-${index}`} file={fileData} />
          ))
        : null}
      {fileParseDone
        ? existingDataList.map((fileData, index) => (
            <PdfPreview key={`existing-${index}`} file={fileData} />
          ))
        : null}
    </>
  );
};

export default memo(Example);

