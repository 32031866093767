
import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { AMOUNT_PER_UNIT, NEW_ARTICLES } from "../../../utils/constants";
import MileageReport from "./other-article-inputs/MileageReport";
import AllowanceOutsideSweden from "./other-article-inputs/AllowanceOutsideSweden";
import TravelExpenses from "./other-article-inputs/TravelExpenses";
import AllowanceInSweden from "./other-article-inputs/AllowanceInSweden";
import MaterialExpenses from "./other-article-inputs/MaterialExpenses";
import { calculateTotalWithVAT, diffInDates } from '../../../utils/commonFunc';

const OtherArticlesForm = ({
    t,
    openModal,
    setOpenModal,
    onSubmitArticleForm,
    onEditArticleForm,
    currency,
    countries,
    allowancePerDay,
    setAllowancePerDay,
    allowancePerDaySweden,
    setAllowancePerDaySweden,
    currencyGlobalValue
  }) => {
    const { key, label, data } = openModal?.item;
    const {
      reset,
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
    } = useForm({
      defaultValues: {
        isTravelExpenseTaxFree: "1",
        isMaterialExpenseTaxFree: "1",
        isAllowanceInSTaxFree: "1",
        isAllowanceOutSTaxFree: "1",
        isMileageReportTaxFree: "1",
        isMileageArticleShowInInvoice: "1",
        isArticleShowInInvoiceOS: "1",
        isArticleShowInInvoiceIS: "1",
        isTravelArticleShowInInvoice: "1",
        isMaterialArticleShowInInvoice: "1",
        isHalfDay: "1",
        allowancePerDay: "",
        isMileagePdfShowToCustomer: "1",
        isAosPdfShowToCustomer: "1",
        isAisPdfShowToCustomer: "1",
        isTravelPdfShowToCustomer: "1",
        isMaterialPdfShowToCustomer: "1",
        country: "",
      },
    });

    const isFileUploaded = watch("uploadFile");
    const isHalfDay = watch("isHalfDay");
    const allowancePerKm = 2.5;
  
    const distance = watch("distance");
    const travelAmount = watch("travelAmount");
    const expensesAmount = watch("expensesAmount");
    const tripStartedDateOs = watch("tripStartedDateOs");
    const tripEndedDateOs = watch("tripEndedDateOs");
    const tripStartedDateIs = watch("tripStartedDateIs");
    const tripEndedDateIs = watch("tripEndedDateIs");
    const isMaterialExpenseTaxFree = watch("isMaterialExpenseTaxFree");
    const isAllowanceInSTaxFree = watch("isAllowanceInSTaxFree");
    const isAllowanceOutSTaxFree = watch("isAllowanceOutSTaxFree");
    const isMileageReportTaxFree = watch("isMileageReportTaxFree");
    const country = watch("country");
    const vat = watch("vat");

    useEffect(() => {
        const countryData = countries?.find(
          (item) => item?.landellerOmråde === country
        );
    
        const amount = Number(countryData?.normalbelopp) || 0;
        setAllowancePerDay(amount);
        if (countryData?.normalbelopp) {
          if (key === NEW_ARTICLES.ALLOWANCE_IN) {
            setAllowancePerDaySweden({
              ...allowancePerDaySweden,
              allowance_in: amount,
            });
          } else if (key === NEW_ARTICLES.ALLOWANCE_OUT) {
            setAllowancePerDaySweden({
              ...allowancePerDaySweden,
              allowance_out: amount,
            });
          }
        }
        setValue("allowancePerDay", amount);
      }, [country, countries, setValue]);
    
      useEffect(() => {
        if (key === NEW_ARTICLES.ALLOWANCE_IN) {
          const value = isHalfDay === "1" ? AMOUNT_PER_UNIT.ALLOWANCE_IN_HALF_DAY : AMOUNT_PER_UNIT.ALLOWANCE_IN_FULL_DAY ;
          setAllowancePerDaySweden({
            ...allowancePerDaySweden,
            allowance_in: value,
          });
          setAllowancePerDay(value);
          setValue("allowancePerDay", value);
        }
      }, [isHalfDay, key, setValue]);
    
      const diffInDays = diffInDates(
        key,
        tripEndedDateIs,
        tripStartedDateIs,
        tripEndedDateOs,
        tripStartedDateOs
      );
    
      const calculateTotal = () => {
        let total = 0.0;
        if (key === NEW_ARTICLES.MATERIAL_VAT) {
          total = parseFloat(expensesAmount) || 0.0;
        } else if (key === NEW_ARTICLES.TRAVEL) {
          total = parseFloat(travelAmount) || 0.0;
        } else if (key === NEW_ARTICLES.ALLOWANCE_IN && diffInDays) {
          total = parseFloat(allowancePerDay || allowancePerDaySweden?.allowance_in) * diffInDays || 0.0;
        } else if (key === NEW_ARTICLES.ALLOWANCE_OUT && diffInDays) {
          total = parseFloat(allowancePerDay || allowancePerDaySweden?.allowance_out) * diffInDays || 0.0;
        } else if (distance) {
          total = parseFloat(allowancePerKm) * parseFloat(distance) || 0.0;
        }
      
        return total.toFixed(2);
      };

        const calculateTotalInSelectedCurrency = () => {
          let total = 0.0;

          if (key === NEW_ARTICLES.MATERIAL_VAT) {
            total = Number((Number(expensesAmount) / Number(currencyGlobalValue)).toFixed(2)) || 0.0;
          } else if (key === NEW_ARTICLES.TRAVEL) {
            total = Number((Number(travelAmount) / Number(currencyGlobalValue)).toFixed(2)) || 0.0;
          } else if (key === NEW_ARTICLES.ALLOWANCE_IN && diffInDays) {
            const perDayAmount = Number(
              (Number(allowancePerDay || allowancePerDaySweden?.allowance_in) / Number(currencyGlobalValue)).toFixed(2)
            );
            total = perDayAmount * Number(diffInDays) || 0.0;
          } else if (key === NEW_ARTICLES.ALLOWANCE_OUT && diffInDays) {
            const perDayAmount = Number(
              (Number(allowancePerDay || allowancePerDaySweden?.allowance_out) / Number(currencyGlobalValue)).toFixed(2)
            );
            total = perDayAmount * Number(diffInDays) || 0.0;
          } else if (distance) {
            const perKmAmount = Number((Number(allowancePerKm) / Number(currencyGlobalValue)).toFixed(2));
            total = perKmAmount * Number(distance) || 0.0;
          }

          return total.toFixed(2);
        };    
      
      function isTruthy(value) {
        return value === "1" || value === 1 || value === true || value === "true";
      }
      

      const checkForValue = (field, value, updated) => {
        if (field === value) {
          return isTruthy(updated);
        }
        return isTruthy("1");
      };
      
      useEffect(() => {
        if (openModal.edit) {
          const resetValues = {
            uploadFile: data?.uploadFile ||  data?.fileContent || data?.uploadfileForInvoice,
            fileName: data?.uploadFile?.[0]?.name || data?.fileName || null,
            isMileageArticleShowInInvoice:
              isTruthy(data?.isMileageArticleShowInInvoice) ||
              checkForValue(NEW_ARTICLES.MILEAGE, key, data?.isArticleShownInInvoice)
                ? "1"
                : "0",
            isArticleShowInInvoiceOS:
              isTruthy(data?.isArticleShowInInvoiceOS) ||
              checkForValue(NEW_ARTICLES.ALLOWANCE_OUT, key, data?.isArticleShownInInvoice)
                ? "1"
                : "0",
            isArticleShowInInvoiceIS:
              isTruthy(data?.isArticleShowInInvoiceIS) ||
              checkForValue(NEW_ARTICLES.ALLOWANCE_IN, key, data?.isArticleShownInInvoice)
                ? "1"
                : "0",
            isTravelArticleShowInInvoice:
              isTruthy(data?.isTravelArticleShowInInvoice) ||
              checkForValue(NEW_ARTICLES.TRAVEL, key, data?.isArticleShownInInvoice)
                ? "1"
                : "0",
            isMaterialArticleShowInInvoice:
              isTruthy(data?.isMaterialArticleShowInInvoice) ||
              checkForValue(NEW_ARTICLES.MATERIAL_VAT, key, data?.isArticleShownInInvoice)
                ? "1"
                : "0",
            isMileagePdfShowToCustomer:
              isTruthy(data?.isMileagePdfShowToCustomer) ||
              checkForValue(NEW_ARTICLES.MILEAGE, key, data?.isPdfAttachedToInvoice)
                ? "1"
                : "0",
            isAosPdfShowToCustomer:
              isTruthy(data?.isAosPdfShowToCustomer) ||
              checkForValue(NEW_ARTICLES.ALLOWANCE_OUT, key, data?.isPdfAttachedToInvoice)
                ? "1"
                : "0",
            isAisPdfShowToCustomer:
              isTruthy(data?.isAisPdfShowToCustomer) ||
              checkForValue(NEW_ARTICLES.ALLOWANCE_IN, key, data?.isPdfAttachedToInvoice)
                ? "1"
                : "0",
            isTravelPdfShowToCustomer:
              isTruthy(data?.isTravelPdfShowToCustomer) ||
              checkForValue(NEW_ARTICLES.TRAVEL, key, data?.isPdfAttachedToInvoice)
                ? "1"
                : "0",
            isMaterialPdfShowToCustomer:
              isTruthy(data?.isMaterialPdfShowToCustomer) ||
              checkForValue(NEW_ARTICLES.MATERIAL_VAT, key, data?.isPdfAttachedToInvoice)
                ? "1"
                : "0",
          };
    
          if (key === NEW_ARTICLES.MILEAGE) {
            Object.assign(resetValues, {
              isMileageReportTaxFree: isTruthy(data?.isMileageReportTaxFree) ||
                isTruthy(data?.isTaxFreeArticle)
                  ? "1"
                  : "0",
              travelDate: data?.travelDate || data?.workingDate?.split("T")[0],
              distance: data?.distance,
              reasonForTrip: data?.reasonForTrip,
              carRegistrationNo: data?.carRegistrationNo,
              tripStartedPlace: data?.tripStartedPlace,
            });
          }
      
          if (key === NEW_ARTICLES.ALLOWANCE_OUT) {
      
            Object.assign(resetValues, {
              tripStartedDateOs: (data?.tripStartedDateOs || data?.tripStartedDate?.split("T")[0]),
              tripEndedDateOs:  (data?.tripEndedDateOs || data?.tripEndedDate?.split("T")[0]),
              isAllowanceOutSTaxFree:  (isTruthy(data?.isAllowanceOutSTaxFree) ||
                          isTruthy(data?.isTaxFreeArticle))
                          ? "1"
                          : "0",
             allowancePerDay: data?.allowancePerDay,
              country: data?.country,
              reasonForTrip: data?.reasonForTrip,
              destination: data?.destination || data?.allowanceDestination,
              isHalfDay: data?.isHalfDay,
            });
          }
      
          if (key === NEW_ARTICLES.ALLOWANCE_IN) {
        
            Object.assign(resetValues, {
              tripStartedDateIs:     (data?.tripStartedDateIs || data?.tripStartedDate?.split("T")[0]),
              tripEndedDateIs:  (data?.tripEndedDateIs || data?.tripEndedDate?.split("T")[0]),
              isAllowanceInSTaxFree:   (isTruthy(data?.isAllowanceInSTaxFree) ||
                          isTruthy(data?.isTaxFreeArticle))
                          ? "1"
                          : "0",
              allowancePerDay: data?.allowancePerDay,
              country: data?.country,
              reasonForTrip: data?.reasonForTrip,
              destination: data?.destination || data?.allowanceDestination,
              isHalfDay: data?.isHalfDay,
            });
          }
      
          if (key === NEW_ARTICLES.TRAVEL) {
            Object.assign(resetValues, {
              vat: data?.vat,
              travelAmount: (data?.travelAmount || data?.amountInSek),
              travelExpDate: (data?.travelExpDate || data?.workingDate?.split("T")[0]),
            });
          }
      
          if (key === NEW_ARTICLES.MATERIAL_VAT) {
            Object.assign(resetValues, {
              isMaterialExpenseTaxFree:
                        (isTruthy(data?.isMaterialExpenseTaxFree) ||
                          isTruthy(data?.isTaxFreeArticle))
                          ? "1"
                          : "0",
              vat: data?.vat,
              expensesAmount: (data?.expensesAmount || data?.amountInSek),
              dateOfPurchase:  (data?.dateOfPurchase || data?.workingDate?.split("T")[0]),
            });
          }
          reset(resetValues);
        }
      }, [openModal.edit]);
    
      const onSubmit = (data) => {
        if (openModal?.edit) {
          onEditArticleForm(data, calculateTotal, calculateTotalInSelectedCurrency);
        } else {
          onSubmitArticleForm(data, calculateTotal, calculateTotalInSelectedCurrency);
        }
      };

      const total = Number(calculateTotal());

      const totalWithVAT = calculateTotalWithVAT(total, vat);

      const keysWithVAT = {
        travel: true,
        material_vat: true,
      };

      const displayTotal = keysWithVAT[key] ? totalWithVAT.toFixed(2) : total.toFixed(2);

      return (
        <Modal
          show
          onHide={() => {
            reset();
            setOpenModal({
              show: false,
            });
          }}
         className="model-style-wrap"
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t(label)}</Modal.Title>
            <AiOutlineClose
              className="ml-auto cursor-pointer"
              size={24}
              onClick={() => {
                reset();
                setOpenModal({
                  show: false,
                });
              }}
            />
          </Modal.Header>
          <form className="article-options-form" onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body className="px-4">
              {(() => {
                switch (key) {
                  case NEW_ARTICLES.MILEAGE:
                    return (
                      <MileageReport
                        register={register}
                        errors={errors}
                        editData={openModal}
                        isFileUploaded={isFileUploaded?.length}
                        setValue={setValue}
                        setOpenModal={setOpenModal}
                        isMileageReportTaxFree={isMileageReportTaxFree !== '0'}
                        t={t}
                      />
                    );
                  case NEW_ARTICLES.ALLOWANCE_OUT:
                    return (
                      <AllowanceOutsideSweden
                        register={register}
                        errors={errors}
                        editData={openModal}
                        isFileUploaded={isFileUploaded?.length}
                        setValue={setValue}
                        setOpenModal={setOpenModal}
                        isAllowanceOutSTaxFree={isAllowanceOutSTaxFree !== '0'}
                        t={t}
                        countries={countries}
                      />
                    );
                  case NEW_ARTICLES.TRAVEL:
                    return (
                      <TravelExpenses
                        register={register}
                        errors={errors}
                        editData={openModal}
                        isFileUploaded={isFileUploaded?.length}
                        setValue={setValue}
                        setOpenModal={setOpenModal}
                        t={t}
                      />
                    );
                  case NEW_ARTICLES.ALLOWANCE_IN:
                    return (
                      <AllowanceInSweden
                        register={register}
                        errors={errors}
                        editData={openModal}
                        isFileUploaded={isFileUploaded?.length}
                        setValue={setValue}
                        setOpenModal={setOpenModal}
                        isAllowanceInSTaxFree={isAllowanceInSTaxFree !== '0'}
                        t={t}
                      />
                    );
                case NEW_ARTICLES.MATERIAL_VAT:
                    return (
                      <MaterialExpenses
                        register={register}
                        errors={errors}
                        editData={openModal}
                        isFileUploaded={isFileUploaded?.length}
                        setValue={setValue}
                        setOpenModal={setOpenModal}
                        isMaterialExpenseTaxFree={isMaterialExpenseTaxFree !== '0'} 
                        t={t}
                      />
                        );                      
                  default:
                    return null;
                }
              })()}
              <div className="d-flex flex-column align-items-end">
                {key === NEW_ARTICLES.ALLOWANCE_OUT || key === NEW_ARTICLES.ALLOWANCE_IN ? (
                  <div className="d-flex mb-1">
                    <label htmlFor="days" className="form-label">
                      {t('Travel days')}:
                    </label>
                    &nbsp;
                    <div>
                      <span>{diffInDays}</span>
                    </div>
                  </div>
                ) : null}
                {key !== NEW_ARTICLES.TRAVEL && key !== NEW_ARTICLES.MATERIAL_VAT && (
                  <div className="mb-1">
                    <label htmlFor="allowancePerKm" className="form-label">
                      {`${t('Allowance per')} ${
                        key === NEW_ARTICLES.ALLOWANCE_OUT || key === NEW_ARTICLES.ALLOWANCE_IN ? t('day') : t('km')
                      }:`}
                    </label>{' '}
                    <span>
                      {(() => {
                        switch (key) {
                          case NEW_ARTICLES.ALLOWANCE_IN:
                            return (allowancePerDay || allowancePerDaySweden?.allowance_in)?.toFixed(2);
                          case NEW_ARTICLES.ALLOWANCE_OUT:
                            return (allowancePerDay || allowancePerDaySweden?.allowance_out)?.toFixed(2);
                          default:
                            return allowancePerKm.toFixed(2);
                        }
                      })()}{' '}
                      SEK
                    </span>
                  </div>
                )}

                <div className="d-flex">
                  <label htmlFor="Total" className="form-label">
                    {t('Total')}
                    {keysWithVAT[key] && ` (inc. VAT) `}:
                  </label>
                  &nbsp;
                  <div>
                  <span>{displayTotal} SEK</span>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  reset();
                  setOpenModal({
                    show: false,
                  });
                }}
              >
                {t('Close')}
              </Button>
              <Button variant="primary" type="submit">
                {openModal?.edit ? t('Update') : t('Add')}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      );
  };

export default OtherArticlesForm;
