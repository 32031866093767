/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

const formatAmount = (amount) => {
  return typeof amount === 'number' ? amount.toFixed(2) : '0.00';
};

const HowCalculatedModal = ({
  t,
  show,
  setShow,
  currency,
  calculatedAmounts,
  otherArticlesData,
  seperatedUserCalculations,
  selectedList,
  isSeparatelyShown,
  setIsSeparatelyShown,
  userInvoiceData,
  customer,
}) => {
  const modalHeight =  '480px' ;

  const invoiceDetailsConfig = [
    { key: 'hourlyWagesAmount', labelKey: 'Payment Per Hour' },
    { key: 'paymentPerDayAmount', labelKey: 'Payment Per Day' },
    { key: 'paymentPerWeekAmount', labelKey: 'Payment Per Week' },
    { key: 'paymentPerMonthAmount', labelKey: 'Payment Per Month' },
    {
      key: 'paymentAsAgreedAmount',
      labelKey: 'Payment As Agreed',
    },
  ];

  const calculateTotalsWithLabels = (data) => {
    const itemsToMap = Object.values(data).flat();

    return itemsToMap.reduce((acc, item) => {
      const existing = acc.find((obj) => obj.label === item.label);
      const value = parseFloat(item.value || 0);

      if (existing) {
        existing.value += value;
      } else {
        acc.push({ label: item.label, value });
      }

      return acc;
    }, []);
  };


  const renderCalculationDetails = (userCalc, userInvoice,index) => {
    const totalsWithLabels = calculateTotalsWithLabels(otherArticlesData);
    return (
      <div className='deduction-content-wrap'>
        <div className="deductionDetails">
          <p className="mb-2">{t('Invoice amount inc. VAT')}</p>
          <p className="m-0 ml-auto">
            {formatAmount(userCalc?.amountIncludingVat)} {currency}
          </p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('VAT')}</p>
          <p className="m-0 ml-auto">
            {formatAmount(userCalc?.vat)} {currency}
          </p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('Invoice amount ex. VAT')}</p>
          <p className="m-0 ml-auto">
            {formatAmount(userCalc?.amount)} {currency}
          </p>
        </div>
        <div className="deductionDetails bg-highlight">
          <p className="mb-2">{t('Invozio fee')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.feeInSEK)} SEK</p>
        </div>
        {customer?.invoiceType == 3 && (
        <div className="deductionDetails bg-highlight">
          <p className="mb-2">{t("Post Fee")}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.totalPostFeeAmount)} SEK</p>
        </div>)}
        <div className="deductionDetails">
          <p className="mb-2">{t('Social fee')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.socialFeeInSek)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('Gross salary incl. holiday pay')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.grossInSek)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('Tax')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.taxInSek)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('Pension')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.pension)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2">{t('Holiday pay')}</p>
          <p className="m-0 ml-auto">{formatAmount(userCalc?.holidayPayAmountInSek)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2 text-primary-custom font-weight-bold">{t('Net salary incl. holiday pay')}</p>
          <p className="m-0 ml-auto text-primary-custom font-weight-bold">{formatAmount(userCalc?.netInSek)} SEK</p>
        </div>
        <div className="deductionDetails">
          <p className="mb-2 text-primary-custom font-weight-bold">{t('Express Fee')}</p>
          <p className="m-0 ml-auto text-primary-custom font-weight-bold">{formatAmount(userCalc?.expressFeeInSek)} SEK</p>
        </div>

        {invoiceDetailsConfig.map((config) => {
          const { key, labelKey } = config;
          const value = isSeparatelyShown ? userInvoice[key] : show.invoiceData[key];
          if (value) {
            return (
              <div className="deductionDetails" key={key}>
                <p className="mb-2">{t(labelKey)}</p>
                <p className="m-0 ml-auto">
                  {`${(labelKey === 'Betalning per timme'|| labelKey ===  'Payment Per Hour') ? value : formatAmount(value)} ${
                    (labelKey === 'Betalning per timme'|| labelKey ===  'Payment Per Hour') ? t('Hours') : currency
                    }`}
                </p>
              </div>
            );
          }
          return null;
        })}
       {index !== undefined 
            ? otherArticlesData?.[index]?.map((d, idx) => (
                <React.Fragment key={idx}>
                  <div className="deductionDetails">
                    <p className="mb-2 text-primary font-weight-bold">
                      {t(d?.label)}
                    </p>
                    <p className="m-0 ml-auto text-primary font-weight-bold">
                      {Number(d?.value).toFixed(2)} SEK
                    </p>
                  </div>
                </React.Fragment>
              ))
            : totalsWithLabels?.map((d, idx) => (
                <React.Fragment key={idx}>
                  <div className="deductionDetails">
                    <p className="mb-2 text-primary font-weight-bold">
                      {t(d?.label)}
                    </p>
                    <p className="m-0 ml-auto text-primary font-weight-bold">
                      {Number(d?.value).toFixed(2)} SEK
                    </p>
                  </div>
                </React.Fragment>
              ))}
      </div>
    );
  };

  return (
    <div>
      <Modal
        className="invoice-modal"
        show={show.showCalculations}
        onHide={() => {
          setShow({ ...show, showCalculations: false });
          setIsSeparatelyShown(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <h5 className="m-0 text-primary-custom mr-2">{t('See how we calculated')}</h5>
          </Modal.Title>
          <AiOutlineClose
            className="ml-auto cursor-pointer"
            size={24}
            onClick={() => {
              setIsSeparatelyShown(false);
              setShow({ ...show, showCalculations: false });
            }}
          />
        </Modal.Header>
        <Modal.Body
          className="bg-secondary-custom position-relative"
          style={{
            minHeight: modalHeight,
            maxHeight: modalHeight,
            overflowY: 'auto',
            width: '100%',
          }}
        >
          {selectedList?.length > 1 && (
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="font-weight-bold" style={{ fontSize: '1.25rem' }}>
                {isSeparatelyShown
                  ? t(`${t('Invoices for')}  ${selectedList?.length} ${t('users')}`)
                  : t('Total')}
              </span>

              <Button
                variant="outline-primary"
                onClick={() => {
                  setIsSeparatelyShown(prevState => !prevState);
                }}
              >
                {isSeparatelyShown ? t('Show Total') : t('Show Separately')}
              </Button>
            </div>
          )}
          {isSeparatelyShown
            ? selectedList?.map((user, index) => {
              const userCalc = seperatedUserCalculations?.[index] || {};
              const userInvoice = userInvoiceData[index] || {}; // Get corresponding userInvoiceData

              return (
                <div key={index} className="mb-4">
                  {selectedList?.length > 1 && (
                    <h5 className="text-primary-custom">
                      <strong>{t(`${user?.signUpUser?.firstName} ${user?.signUpUser?.lastName}`)}</strong>
                    </h5>
                  )}
                 
                  {renderCalculationDetails(userCalc, userInvoice,index)} <hr className="my-3" />
                </div>
              );
            })
            : renderCalculationDetails(calculatedAmounts)}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="theme_btn"
            onClick={() => {
              setIsSeparatelyShown(false);
              setShow({
                ...show,
                showCalculations: false,
              });
            }}
          >
            {t('Done')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HowCalculatedModal;