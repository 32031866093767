import React,{ useState } from "react";
import { MdDelete } from "react-icons/md";

import { toast } from "react-toastify";
import { NEW_ARTICLES } from "../../../../utils/constants";

const TravelExpenses = ({
  register,
  errors,
  editData,
  isFileUploaded,
  setValue,
  setOpenModal,
  t,
}) => {
  const { key, label, data } = editData?.item;
  const language = localStorage.getItem("i18nextLng");
  const fileName = (isFileUploaded && data?.uploadFile?.[0]?.name) || data?.fileName;
  const [fileSize, setFileSize] = useState(null);

  return (
    <>
      <div className="form-row mb-3">
        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="uploadFile" className="text-muted small">
            {`${t("Upload File")} (${t("PDF files up to 1 MB only")})`}
          </label>

          {/* if file is uploaded */}
          {fileName ? (
            <div className="mb-2 d-flex align-items-center flex-wrap">
              <span className="text-success">
                {fileName}
              </span>
              <MdDelete
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setValue("uploadFile", null);
                  setValue("fileName", null);
                  setOpenModal((prev) => ({
                    ...prev,
                    item: {
                      ...prev.item,
                      data: {
                        ...prev.item.data,
                        uploadFile: null,
                        fileName: null,
                      },
                    },
                  }));
                }}
              />
            </div>
          ) : (
            // if file is not uploaded
            <input
              type="file"
              accept=".pdf"
              className={`form-control-file p-2 rounded border ${errors.uploadFile ? "border-danger" : ""
                }`}

              {...register("uploadFile", {
                required:
                  (key === NEW_ARTICLES.TRAVEL || key === NEW_ARTICLES.MATERIAL_VAT) &&
                    !isFileUploaded
                    ? true
                    : "",
                validate: (value) => {
                  if (value && value[0]) {
                    const file = value[0];
                    const isPDF = file?.type === "application/pdf";
                    const sizeInMB = file.size / (1024 * 1024);
                    setFileSize(sizeInMB.toFixed(2));
                    if (!isPDF) {
                      toast.error(t("Please upload a valid PDF file."));
                      return "Invalid file type.";
                    }
                    if(sizeInMB > 1){
                      toast.error(t("Please select a file up to 1 MB in size."));
                      return "Invalid file size.";
                    }
                    setValue("fileName", value[0].name);
                  }
                  return true;
                },
              })}
            />
          )}
        </div>

        {!isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label
              htmlFor="isTravelArticleShowInInvoice"
              className="text-muted small"
            >
              {t("Do you want to show the {{label}} on the invoice?", {
                label
              })}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelArticleShowInInvoice", {
                    required: true,
                  })}
                  value="1"
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="showYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelArticleShowInInvoice", {
                    required: true,
                  })}
                  value="0"
                />
                <label className="form-check-label text-muted" htmlFor="showNo">
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="showPdfToCustomer" className="text-muted small">
              {t("Do you want to show the PDF to the customer?")}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelPdfShowToCustomer ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelPdfShowToCustomer", {
                    required: isFileUploaded && true,
                  })}
                  value="1"
                />
                <label
                  className={`form-check-label ${!errors.isTravelPdfShowToCustomer && "text-muted"
                    } `}
                  htmlFor="pdfShowYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelPdfShowToCustomer ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelPdfShowToCustomer", {
                    required: isFileUploaded && true,
                  })}
                  value="0"
                />
                <label
                  className={`form-check-label ${!errors.isTravelPdfShowToCustomer && "text-muted"
                    }`}
                  htmlFor="pdfShowNo"
                >
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label
              htmlFor="isTravelArticleShowInInvoice"
              className="text-muted small"
            >
              {t("Do you want to show the {{label}} on the invoice?", {
                label
              })}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelArticleShowInInvoice", {
                    required: true,
                  })}
                  value="1"
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="showYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isTravelArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isTravelArticleShowInInvoice", {
                    required: true,
                  })}
                  value="0"
                />
                <label className="form-check-label text-muted" htmlFor="showNo">
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {!isFileUploaded && (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="travelExpDate" className="text-muted small">
              {t("Travel Date")}
            </label>
            <input
              type="date"
              className={`form-control form-control-lg mb-3 ${errors.travelExpDate ? "is-invalid" : ""
                }`}
              {...register("travelExpDate", {
                required: true,
              })}
            />
          </div>
        )}
      </div>

      <div className="form-row mb-3">
        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="travelExpDate" className="text-muted small">
              {t("Travel Date")}
            </label>
            <input
              type="date"
              className={`form-control form-control-lg mb-3 ${errors.travelExpDate ? "is-invalid" : ""
                }`}
              {...register("travelExpDate", {
                required: true,
              })}
            />
          </div>
        ) : (
          ""
        )}

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="vat" className="text-muted small">
            {t("VAT")}
          </label>
          <select
            className={`form-control shadow-sm rounded vat-option ${errors.vat ? "is-invalid" : ""
              }`}
            {...register("vat", {
              required: true,
            })}
            id="vat"
          >
            <option value="" disabled selected>
              {t("Select VAT")}
            </option>{" "}
            <option value="0">0%</option>
            <option value="6">6%</option>
            <option value="12">12%</option>
            <option value="25">25%</option>
          </select>
        </div>
        {!isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="travelAmount" className="text-muted small">
             {t("Amount in SEK (ex. VAT)")}
            </label>
            <input
              type="number"
              step=".01"
              className={`form-control shadow-sm rounded ${errors.travelAmount ? "is-invalid" : ""
                }`}
              {...register("travelAmount", { required: true, min: 0 })}
              id="travelAmount"
              placeholder={t("Enter amount (ex. VAT)")}
            />
          </div>
        ) : (
          ""
        )}
        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="travelAmount" className="text-muted small">
             {t("Amount in SEK (ex. VAT)")}
            </label>
            <input
              type="number"
              step=".01"
              className={`form-control shadow-sm rounded ${errors.travelAmount ? "is-invalid" : ""
                }`}
              {...register("travelAmount", { required: true, min: 0 })}
              id="travelAmount"
              placeholder={t("Enter amount (ex. VAT)")}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default TravelExpenses;
