import { NEW_ARTICLES } from "./constants";

export const otherCost = '93';
export const HourlyWages = '92';
export const InvoiveAsDescribedBellow = '2';
export const costPerDay = '94';
export const costPerWeek = '95';
export const costPerMonth = '96';
export const costAgreement = '97';

export const otherArticleOptions = [
    {
      id: 1,
      label: "Mileage Report",
      key: NEW_ARTICLES.MILEAGE,
    },
    {
      id: 2,
      label: "Travel Expenses as per attached PDF",
      key: NEW_ARTICLES.TRAVEL,
    },
    {
      id: 3,
      label: "Expenses for materials and tools as per attached PDF",
      key: NEW_ARTICLES.MATERIAL_VAT,
    },
    {
      id: 4,
      label: "Allowance outside Sweden",
      key: NEW_ARTICLES.ALLOWANCE_OUT,
    },
    {
      id: 5,
      label: "Allowance in Sweden",
      key: NEW_ARTICLES.ALLOWANCE_IN,
    },
  ];

  export const englishTranslations = {
    'Betalning per timme': 'Payment Per Hour',
    'Betalning per dag': 'Payment Per Day',
    'Betalning per vecka': 'Payment Per Week',
    'Betalning per månad': 'Payment Per Month',
    'Betalning enligt överenskommelse': 'Payment As Agreed',
    'Tim': 'hours',
    'Dag': "day",
    "Vecka":"week",
    "m":"month",
    "a":"agreed",
    "h":"hours",
    "d":"day",
    "w":"week",
  };
  