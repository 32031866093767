/* eslint-disable */
import { filter, update } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import countries from 'countries-list';
import axios from 'axios';
import NoteIcon from '@mui/icons-material/Note';
import ClearIcon from '@mui/icons-material/Clear'; // Import the close icon
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ErrosAlert from './ErrosAlert';
import { vatFormats, validateVatNumber } from 'src/utils/formatNumber';
// material
import { TextField, Snackbar, TableBody, TableCell, Container } from '@mui/material';
import { Autocomplete, Typography, TableContainer, TablePagination, IconButton } from '@mui/material';
import { Button, Card, Alert, Table, Stack, Box, Modal, Tooltip, TableHead, TableRow } from '@mui/material';
// components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import HistoryIcon from '@mui/icons-material/History';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';
import SearchNotFound from '../../../components/SearchNotFound';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import Loader from '../../../components/Loader';
import { customerDropOptions, formatDate, getModalBoxStyle } from '../../../utils/commonFunc';
import TooltipWithCopy from '../../../components/TooltipWithCopy';
// import RenderCustomers from './RenderCustomers';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'customerId', label: 'Id', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'accountType', label: 'Business Type', alignRight: false },
  { id: '', label: 'Actions' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const escapeRegExp = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    const searchRegex = new RegExp(escapeRegExp(query), 'i');
    return filter(array, (_row) =>
      Object.keys(_row).some((field) => {
        if (_row[field]) return searchRegex.test(_row[field].toString());
        return false;
      })
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [showCustomerDetails, setShowCustomerDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [totalCount, setTotalCount] = useState(0);
  const [isAllSelected, setAllSelected] = useState(false);
  const [toggleNote, setToggleNote] = useState(false);
  const [notesContent, setNotesContent] = useState('');
  const [isNotesContentEmpty, setIsNotesContentEmpty] = useState(false);
  const [commentList, setcommentList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [countryVal, setCountryValue] = useState(false);
  const [userAccountType, setUserAccountType] = useState('');
  const [invoiceType, setInvoiceType] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [searchDropValue, setSearchDropValue] = useState();
  const [selectedTabValue, setSelectedTabValue] = useState('1');
  const [isVatRegisterSelected, setIsVatRegisterSelected] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [showSyncModal, setshowSyncModal] = useState(false);
  const [isError, setisError] = useState(false);
  const [messages, setMessages] = useState({
    customerUpdatedOnFortnox: null,
    customerUpdatedOnFortnoxError: null,
    customeraddedOnFortnox: null,
    customeraddedOnFortnoxError: null,
    invoiceCanceledOnFortnox: null,
    invoiceCanceledOnFortnoxError: null,
    invoiceFileuploadedOnFortnox: null,
    invoiceFileuploadedOnFortnoxError: null,
    invoiceTaxReductioncreatedOnFortnox: null,
    invoiceTaxReductioncreatedOnFortnoxError: null,
    invoiceUpdatedOnFortnox: null,
    invoiceUpdatedOnFortnoxError: null,
    invoiceaddedOnFortnox: null,
    invoiceaddedOnFortnoxError: null,
  });

  const { userId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    register,
    reset,
    watch,
    handleSubmit,
    resetField,
    setValue,
    formState: { errors },
  } = useForm();

  const selectedCountry = watch('country');
  const isVATRegistred = watch('isVATRegistred');

  const fetchCustomers = useCallback(async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/user/all-customers?PageSize=${rowsPerPage}&PageNumber=${page + 1
          }&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/user/all-customers?PageSize=${rowsPerPage}&PageNumber=${page + 1}&searchQuery=${searchQuery}`;
      }

      const res = await axios.get(URL);
      if (res?.status === 200) {
        setLoading(false);
        const customerId = new URLSearchParams(search).get('notificationVal');

        const editCustomers = res?.data?.response?.map((ele) => ({ ...ele, isEdit: false }));

        const filteredRows = applySortFilter(editCustomers, getComparator(order, orderBy), searchQuery);

        const notificationFilteredList = filteredRows?.map((item) => ({
          ...item,
          isSelected: item.customerId === Number(customerId),
        }));
        setCustomers(notificationFilteredList);
        selectAllClick(isAllSelected, notificationFilteredList);

        setTotalCount(res.data.totalCount);

        setFilteredCustomers(notificationFilteredList);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchCustomers();
        setLoading(true);
      } else {
        setCustomers([]);
        setLoading(false);
      }
      console.log(err);
    }
  }, [userId, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    if (showCustomerDetails) {
      const vatStatus = showCustomerDetails.isVATRegistred === 'true' || showCustomerDetails.isVATRegistred === true;
      setIsVatRegisterSelected(vatStatus);
      setValue('isVATRegistred', vatStatus ? 'true' : 'false');
      setIsInitialLoad(false);
    }
  }, [showCustomerDetails, setValue]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers, page, rowsPerPage, searchQuery]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const selectAllClick = (isSelected, filteredCustomers) => {
    if (isSelected) {
      const newSelecteds = filteredCustomers?.map((n) => n.customerId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredCustomers);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };


  const filteredRows = applySortFilter(customers, getComparator(order, orderBy), searchQuery);
  const isNotFound = filteredRows.length === 0;
  const getUserDetails = (id) => {
    const customer = customers.find((customers) => customers.customerId === id);

    setShowCustomerDetails(customer);
    setShowEditModal(true);

    const characters = customer?.vatNumber?.match(/[a-zA-Z]+/g);
    const characterResult = characters ? characters.join('').slice(0, 2) : '';
    setCountryCode(characterResult);

    const numberResult = customer?.vatNumber?.slice(2);

    setUserAccountType(
      (customer.accountType === 1 && 'Private Customer') ||
      (customer.accountType === 2 && 'Business Customer') ||
      (customer.accountType === 3 && 'Business Construction')
    );

    setInvoiceType(
      (customer.customerAddress[0].invoiceType === 1 && 'Email (send to email address)') ||
      (customer.customerAddress[0].invoiceType === 2 && 'E-invoice (send as electronic invoice)') ||
      (customer.customerAddress[0].invoiceType === 3 && 'Post (60 SEK)')
    );
    reset({
      name: customer?.name,
      email: customer?.email,
      orgNo: customer?.orgNo,
      ssn: customer?.ssn,
      country: customer?.country,
      phoneNumber: customer?.phoneNumber,
      isVATRegistred: customer?.isVATRegistred,
      countryCode: customer?.countryCode,
      zipCode: customer?.zipCode,
      address: customer?.address,
      address2: customer?.address2,
      city: customer?.city,
      customerId: customer?.customerId,
      reference: customer?.reference,
      vatNumber: numberResult,
      co: customer.co,
      paymentTerm: customer.paymentTerm,
      invoiceType:
        (customer?.customerAddress[0]?.invoiceType === 1 && 'Email (send to email address)') ||
        (customer?.customerAddress[0]?.invoiceType === 2 && 'E-invoice (send as electronic invoice)') ||
        (customer?.customerAddress[0]?.invoiceType === 3 && 'Post (60 SEK)'),
      accountType:
        (customer.accountType === 1 && 'Private Customer') ||
        (customer.accountType === 2 && 'Business Customer') ||
        (customer.accountType === 3 && 'Business Construction'),
        yourElectronicReference: customer?.customerAddress[0].yourElectronicReference,
        extraField1: customer?.customerAddress[0]?.extraField1,
        extraField2: customer?.customerAddress[0]?.extraField2,
        globalLocationNumber: customer?.customerAddress ? customer?.customerAddress?.[0]?.globalLocationNumber : ""
    });
  };

  const editUserDetails = async (values) => {
    let cCode = '';
    if (
      values?.accountType !== 'Private Customer' &&
      values?.accountType != '1' &&
      (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) &&
      !values?.vatNumber
    ) {
      return toast.error('Please enter your VAT number');
    }

    Object.keys(countries.countries).forEach((code) => {
      if (countries.countries[code].name === values.country) {
        setCountryCode(code);
        cCode = code;
      }
    });

    try {
      setLoading(true);

      let concatVatNumber;
      if (values?.accountType == '1' || values?.accountType == 'Private Customer') {
        values.vatNumber = '';
      } else if (values?.isVATRegistred === 'false' || values?.isVATRegistred === false) {
        values.vatNumber = '';
      } else if (values?.isVATRegistred === 'true' || values?.isVATRegistred === true) {
        concatVatNumber = values?.countryCode + values.vatNumber;
        values.vatNumber = concatVatNumber;
      }

      await axios.put('/admin/user', {
        ...values,
        countryCode,
        invoiceType:
          (values.invoiceType === 'Email (send to email address)' && 1) ||
          (values.invoiceType === 'E-invoice (send as electronic invoice)' && 2) ||
          (values.invoiceType === 'Post (60 SEK)' && 3),
        accountType:
          (values.accountType === 'Private Customer' && 1) ||
          (values.accountType === 'Business Customer' && 2) ||
          (values.accountType === 'Business Construction' && 3),
        id: showCustomerDetails.customerId,
        countryCode: countryCode ? countryCode : cCode,
      });
      setShowEditModal(false);
      setAlertMessage('Details has been updated successfully.');
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
      await fetchCustomers();
    } catch (err) {
      console.log(err);
      setAlertMessage(err?.response?.data?.message);
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const newDetails = [{ label: 'Sweden' }, { label: 'USA' }, { label: 'Canada' }, { label: 'Panama' }];

  Object.keys(countries.countries).forEach((code) => {
    newDetails.push({ label: countries.countries[code].name });
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // Add Notes

  const {
    reset: resetForm3,
    register: registerForm3,
    handleSubmit: handleSubmitForm3,
    formState: { errors: errorsForm3 },
  } = useForm();

  const commentStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '60%',
    height: '80%',
    marginBottom: '40',
    p: 3,
  };

  const addnoteToggle = (id) => {
    setToggleNote(true);
    resetForm3({
      description: '',
    });
    setSelectedCustomer(id);
  };

  const postAddnotes = async () => {
    try {
      if (notesContent === '' || notesContent.trim() === '') {
        setIsNotesContentEmpty(true);
        setNotesContent('');
      } else {
        setIsNotesContentEmpty(false);
        setLoading(true);
        const response = await axios.post('admin/user/customer-notes', {
          userId: 0,
          invoiceId: 0,
          customerId: selectedCustomer,
          description: notesContent,
        });

        if (response?.status === 200) {
          setLoading(false);
          fetchCommentList();
          setNotesContent('');
          toast.success('Notes added successfully');
          fetchCustomers();
        } else {
          setLoading(false);
          toast.error('Server Error! Please try again');
        }
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err?.message);
      }
      setAlertStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const fetchCommentList = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`admin/user/get-customer-notes?customerId=${selectedCustomer}`);
      setcommentList(res?.data?.response);
    } catch (err) {
      setcommentList([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toggleNote) {
      fetchCommentList();
    }
  }, [toggleNote, selectedCustomer]);

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const customersList = ['Private', 'Business Customer', 'Business Construction'];

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    setSelectedTabValue(newValue);
    setAllSelected(false);
  };

  const handleSyncProceed = async () => {
    try {
      setLoading(true);
      const Url = '/admin/invoice/sync-all-data-to-fortnox';
      const result = await axios.post(Url);
      if (result?.data?.message === 'error') {
        const errors = {
          customerUpdatedOnFortnoxError: result?.data?.response?.customerUpdatedOnFortnoxError,
          customeraddedOnFortnoxError: result?.data?.response?.customeraddedOnFortnoxError,
          invoiceCanceledOnFortnoxError: result?.data?.response?.invoiceCanceledOnFortnoxError,
          invoiceFileuploadedOnFortnoxError: result?.data?.response?.invoiceFileuploadedOnFortnoxError,
          invoiceTaxReductioncreatedOnFortnoxError: result?.data?.response?.invoiceTaxReductioncreatedOnFortnoxError,
          invoiceUpdatedOnFortnoxError: result?.data?.response?.invoiceUpdatedOnFortnoxError,
          invoiceaddedOnFortnoxError: result?.data?.response?.invoiceaddedOnFortnoxError,
          // Without Error Data
          customerUpdatedOnFortnox: result?.data?.response?.customerUpdatedOnFortnox,
          customeraddedOnFortnox: result?.data?.response?.customeraddedOnFortnox,
          invoiceCanceledOnFortnox: result?.data?.response?.invoiceCanceledOnFortnox,
          invoiceFileuploadedOnFortnox: result?.data?.response?.invoiceFileuploadedOnFortnox,
          invoiceTaxReductioncreatedOnFortnox: result?.data?.response?.invoiceTaxReductioncreatedOnFortnox,
          invoiceUpdatedOnFortnox: result?.data?.response?.invoiceUpdatedOnFortnox,
          invoiceaddedOnFortnox: result?.data?.response?.invoiceaddedOnFortnox,
        };
        setMessages(errors);
        setisError(true);
        setshowSyncModal(false);
        const isUnauthorizedError = Object.values(errors).some(
          (error) => error && error.includes('Request failed: unauthorized')
        );
        if (isUnauthorizedError) {
          toast.error('Fortnox token is invalid');
        }
      }
      if (result?.data?.message === 'Success') {
        setMessages({
          customerUpdatedOnFortnox: result?.data?.response?.customerUpdatedOnFortnox,
          customeraddedOnFortnox: result?.data?.response?.customeraddedOnFortnox,
          invoiceCanceledOnFortnox: result?.data?.response?.invoiceCanceledOnFortnox,
          invoiceFileuploadedOnFortnox: result?.data?.response?.invoiceFileuploadedOnFortnox,
          invoiceTaxReductioncreatedOnFortnox: result?.data?.response?.invoiceTaxReductioncreatedOnFortnox,
          invoiceUpdatedOnFortnox: result?.data?.response?.invoiceUpdatedOnFortnox,
          invoiceaddedOnFortnox: result?.data?.response?.invoiceaddedOnFortnox,
        });
        setisError(true);
        toast.success('The Entire data of customer and invoice is sync to fortnox');
      }
      fetchCustomers();
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
      setshowSyncModal(false);
    }
  };

  const handleButtonClick = async (customerId) => {
    setLoading(true);
    try {
      const result = await axios.post(`/admin/user/sent-incorrect-info-mail?id=${customerId}&type=1`);
      if (result?.data?.message === 'Success') {
        toast.success(result?.data?.response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const RenderCustomers = () => {
    return (
      <>
        <ListToolbar
          hideEmailButton={'true'}
          numSelected={selected.length}
          searchQuery={searchQuery}
          onFilter={handleFilter}
          dropOptions={customerDropOptions}
          searchDropValue={searchDropValue}
          setSearchDropValue={setSearchDropValue}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={customers.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                hideCheckboxColumnHeader={'true'}
              />
              <TableBody>
                {filteredRows?.map((row) => {
                  const { customerId, name, email, accountType, isNotes } = row;
                  const isItemSelected = selected.indexOf(customerId) !== -1;

                  return (
                    <TableRow
                      hover
                      key={customerId}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      style={{
                        backgroundColor: row?.isSelected
                          ? 'lightgrey'
                          : !row?.isAddedOnFortnox || !row?.isDetailsUpdatedOnFortnox
                            ? 'rgb(248 232 232)'
                            : '',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell align="left">{customerId}</TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">{customersList[accountType - 1]}</TableCell>
                      <TableCell align="right">
                        <div className="d-flex">
                          <Button
                            type="submit"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Notes"
                            variant={isNotes ? '' : 'contained'}
                            onClick={() => {
                              addnoteToggle(customerId);
                            }}
                            style={{
                              width: 50,
                              minWidth: '50px',
                              fontSize: '12px',
                              padding: '6px',
                              marginLeft: '0px',
                              marginRight: '10px',
                            }}
                          >
                            <NoteIcon style={{ color: isNotes && 'rgb(226,204,97)' }}>NoteIcon</NoteIcon>
                          </Button>

                          <IconButton
                            onClick={(event) => {
                              getUserDetails(customerId);
                              event.stopPropagation();
                            }}
                            style={{ color: 'rgb(255, 255, 255)', background: '#62b5e5' }}
                          >
                            <Iconify icon="material-symbols:edit" width={24} height={24} />
                          </IconButton>
                          <Tooltip title="View logs">
                            <Button
                              type="submit"
                              onClick={() =>
                                navigate(`/dashboard/view-logs?type=customer&id=${customerId}`, {
                                  state: {
                                    type: 'name',
                                    name,
                                  },
                                })
                              }
                              style={{
                                width: 50,
                                minWidth: '50px',
                                fontSize: '12px',
                                padding: '6px',
                                marginLeft: '8px',
                              }}
                            >
                              <HistoryIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={searchQuery} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        {/* Add Notes  Modal */}
        <Modal
          open={toggleNote}
          onClose={() => {
            setToggleNote(false);
            setNotesContent('');
            setIsNotesContentEmpty(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={commentStyle}>
            <Box sx={{ height: 'fit-content' }} id="usersList-modal">
              <IconButton
                color="inherit"
                onClick={() => {
                  setToggleNote(false);
                  setNotesContent('');
                  setIsNotesContentEmpty(false);
                }}
                sx={{
                  position: 'absolute',
                  right: 5,
                  top: 0,
                }}
                aria-label="close"
              >
                <ClearIcon style={{ fontSize: 26 }} />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: '95%' }}>
                Notes
              </Typography>
              <Form>
                <Form.Group className="modalform" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    style={{
                      resize: 'none',
                      width: '50%',
                      borderRadius: '12px',
                      borderColor: isNotesContentEmpty && '#eb5e5e',
                    }}
                    onChange={(e) => setNotesContent(e.target.value)}
                    value={notesContent}
                  />
                  <Button variant="contained" style={{ height: '40px', marginLeft: '20px' }} onClick={postAddnotes}>
                    <AddCircleIcon />
                  </Button>
                </Form.Group>
              </Form>
              <div style={{ height: '250px', overflow: 'auto', marginTop: '10px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date </TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Added By</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    ''
                  ) : (
                    <TableBody>
                      {commentList?.length > 0 ? (
                        commentList?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell> {formatDate(row?.createdDate)}</TableCell>
                            <TableCell>
                              {row?.description?.length > 30 ? (
                                <TooltipWithCopy text={row?.description} />
                              ) : (
                                row?.description
                              )}
                            </TableCell>
                            <TableCell> {row?.addedBy} </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow className="text-center">
                          <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                            No record found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </div>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setShowCustomerDetails(null);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit Customer Details
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <form
                onSubmit={handleSubmit((values) => {
                  editUserDetails(values);
                })}
              >
                <div className="container" style={{ overflow: 'auto', height: '450px', width: '105%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      '& .MuiTextField-root': { width: '25ch' },
                    }}
                  >
                    <Autocomplete
                      freeSolo
                      value={watch('accountType')}
                      id="combo-box-demo"
                      options={[
                        { label: 'Private Customer' },
                        { label: 'Business Customer' },
                        { label: 'Business Construction' },
                      ]}
                      sx={{ width: 300, marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register('accountType', {
                            required: true,
                          })}
                          label="AccountType"
                        />
                      )}
                      onChange={(event, val) => setUserAccountType(val?.label)}
                    />

                    <Autocomplete
                      freeSolo
                      value={watch('invoiceType') || ''}
                      id="combo-box-demo"
                      options={[{ label: 'Email (send to email address)' }, { label: 'E-invoice (send as electronic invoice)' }, { label: 'Post (60 SEK)' }]}
                      sx={{ width: 300, marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register('invoiceType', {
                            required: true,
                          })}
                          label="Invoice Type"
                        />
                      )}
                      onChange={(event, val) => setInvoiceType(val?.label)}
                    />

                    {invoiceType === 'E-invoice (send as electronic invoice)' && (
                      <>
                        <TextField
                          label={'Our electronic reference'}
                          {...register('ourElectronicReference', {
                            required: false,
                          })}
                          defaultValue="7230020"
                          InputProps={{ readOnly: true, style: { color: '#757575' } }}
                          id="margin-none"
                          margin="normal"
                        />

                        <TextField
                          label={'Your electronic reference'}
                          {...register('yourElectronicReference', {
                            required: true,
                          })}
                          id="margin-none"
                          margin="normal"
                        />

                        <TextField
                          label={"GLN / Peppol ID"}
                          {...register('globalLocationNumber', {
                            required: true,
                            maxLength: 13,
                            minLength: 13
                          })}
                          id="margin-none"
                          margin="normal"
                        />

                        <TextField
                          label={'Extra field 1 e-invoice (optional)'}
                          {...register('extraField1', {
                            required: false,
                          })}
                          id="margin-none"
                          margin="normal"
                        />

                        <TextField
                          label={'Extra field 2 e-invoice (optional)'}
                          {...register('extraField2', {
                            required: false,
                          })}
                          id="margin-none"
                          margin="normal"
                        />
                      </>
                    )}

                    <TextField
                      label={'Name'}
                      {...register('name', {
                        required: true,
                      })}
                      id="margin-none"
                      margin="normal"
                    />
                    <TextField
                      type="email"
                      label={'Email'}
                      {...register('email', {
                        required: true,
                      })}
                      id="margin-normal"
                      margin="normal"
                    />
                    {watch('accountType') === 'Private Customer' ? (
                      <TextField
                        label={'SSN'}
                        {...register('ssn', {
                          required: true,
                        })}
                        onKeyDown={(e) => {
                          const inputValue = e.key;
                          const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                          if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                            e.preventDefault();
                          }
                        }}
                        id="margin-none"
                        margin="normal"
                      />
                    ) : (
                      <TextField
                        label={'OrgNo'}
                        {...register('orgNo', {
                          required: true,
                        })}
                        id="margin-none"
                        margin="normal"
                        onKeyDown={(e) => {
                          const inputValue = e.key;
                          const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                          if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                    <Autocomplete
                      freeSolo
                      value={selectedCountry}
                      id="combo-box-demo"
                      options={newDetails}
                      sx={{ width: 300, marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField {...params} {...register('country', { required: true })} label="Country" />
                      )}
                      onChange={(event, newValue) => {
                        const selectedCountry = newValue;
                        setCountryValue(selectedCountry?.label === 'Sweden');
                        Object.keys(countries.countries).forEach((code) => {
                          if (countries.countries[code].name === newValue?.label) {
                            setCountryCode(code);
                          }
                          if (newValue?.label === 'USA') {
                            setCountryCode('US');
                          }
                          if (newValue?.label === 'Sweden') {
                            setCountryValue(true);
                          }
                        });
                        resetField('vatNumber', { defaultValue: '' });
                      }}
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        // This regular expression allows any character except digits (0-9)
                        const disallowedCharacters = /^[0-9]$/;

                        if (inputValue !== 'Backspace' && disallowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                    />

                    {userAccountType !== 'Private Customer' && (
                      <div style={{ width: 249 }} className="">
                        <label className="ml-2" htmlFor="have_vatnumber">
                          {'Do you have VAT number?'}
                        </label>
                        <select
                          style={{ height: 53 }}
                          id="have_vatnumber"
                          className="form-control pt-2 pb-2 form-control-md mb-3"
                          {...register('isVATRegistred', { required: true })}
                          defaultValue={isVatRegisterSelected ? 'true' : 'false'}
                          onChange={(e) => {
                            const value = e.target.value === 'true';
                            setIsVatRegisterSelected(value); // Update the state based on user selection
                            setValue('isVATRegistred', e.target.value); // Update the form value for isVATRegistred
                          }}
                        >
                          <option value="true">{'Yes'}</option>
                          <option value="false">{'No'}</option>
                        </select>
                      </div>
                    )}

                    {userAccountType !== 'Private Customer' && isVatRegisterSelected && (
                      <div style={{ display: 'flex' }}>
                        <TextField
                          sx={{ marginTop: '12px' }}
                          id="left-box"
                          label={'Code'}
                          style={{ flex: 1, width: '50px' }}
                          value={countryCode}
                          aria-readonly="true"
                        />

                        <TextField
                          sx={{ marginTop: '12px' }}
                          label={'Vat. Number'}
                          {...register('vatNumber', {
                            required: countryVal === false && userAccountType !== 'Private Customer',
                            validate: (value) => validateVatNumber(countryCode, value),
                          })}
                          id="right-box"
                          style={{ flex: 4 }}
                          onKeyDown={(e) => {
                            const allowedCharacters =
                              vatFormats[countryCode]?.allowedCharacters || /^[ A-Za-z0-9_@./#&+-]*$/;
                            if (
                              allowedCharacters.test(e.key) ||
                              ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)
                            ) {
                              return true;
                            } else {
                              e.preventDefault();
                              return false;
                            }
                          }}
                          onInput={(e) => {
                            const input = e.target;
                            const maxLength = vatFormats[countryCode]?.maxLength || 18;
                            let value = input.value;
                            if (value.length > maxLength) {
                              value = value.slice(0, maxLength);
                              input.value = value;
                            }
                          }}
                          error={!!errors.vatNumber}
                          helperText={errors.vatNumber ? errors.vatNumber.message : ''}
                        />
                      </div>
                    )}

                    <TextField
                      label={'PhoneNo'}
                      {...register('phoneNumber', {
                        required: true,
                      })}
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                        if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                      id="margin-normal"
                      margin="normal"
                    />
                    <TextField
                      label={'ZipCode'}
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;
                        if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                      {...register('zipCode', {
                        required: true,
                      })}
                      id="margin-none"
                      margin="normal"
                      onInput={(e) => {
                        const maxLength = 9;
                        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                        if (e.target.value.length > maxLength) {
                          e.target.value = e.target.value.slice(0, maxLength);
                        }
                      }}
                    />
                    <TextField
                      label={'Address'}
                      {...register('address', {
                        required: true,
                      })}
                      id="margin-dense"
                      margin="normal"
                    />
                    {userAccountType !== 'Private Customer' && (
                      <TextField label={'Address 2 (optional)'} {...register('address2')} id="margin-dense" margin="normal" />
                    )}
                    <TextField
                      label={'City'}
                      {...register('city', {
                        required: true,
                      })}
                      id="margin-normal"
                      margin="normal"
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        // This regular expression allows any character except digits (0-9)
                        const disallowedCharacters = /^[0-9]$/;

                        if (inputValue !== 'Backspace' && disallowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <TextField
                      label={'Reference'}
                      {...register('reference', {
                        required: true,
                      })}
                      id="margin-normal"
                      margin="normal"
                    />
                    <TextField label={'CO'} {...register('co')} id="margin-normal" margin="normal" />
                    <Autocomplete
                      freeSolo
                      value={watch('paymentTerm')}
                      id="combo-box-demo"
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                        if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                      options={[
                        { label: 0 },
                        { label: 10 },
                        { label: 15 },
                        { label: 20 },
                        { label: 30 },
                        { label: 45 },
                        { label: 60 },
                      ]}
                      sx={{ width: 300, marginTop: '12px' }}
                      renderInput={(params) => (
                        <TextField {...params} {...register('paymentTerm')} label="PaymentTerm" />
                      )}
                    />
                  </Box>
                </div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowEditModal(false);
                    setShowCustomerDetails(null);
                  }}
                >
                  Close
                </Button>
                <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
                  Save Changes
                </Button>
              </form>
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={showSyncModal}
          onClose={(prevState) => {
            setshowSyncModal(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={getModalBoxStyle()}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Are you sure you want to sync?
            </Typography>

            <div style={{ margin: '25px 0 0', float: 'right', gap: '10px', display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={(prevState) => {
                  setshowSyncModal(false);
                }}
                sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                autoFocus
              >
                No
              </Button>

              {!isError && (
                <Button onClick={handleSyncProceed} sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                  Yes
                </Button>
              )}
            </div>
          </Box>
        </Modal>

        <TablePagination
          rowsPerPageOptions={[100, 250, 500]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return (
    <Page title="Customers">
      {loading && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" alignItems="center">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }}>
              Customers
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Tooltip arrow title={'Sync To Fortnox'}>
              <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => setshowSyncModal(true)}>
                Sync To Fortnox
              </Button>
            </Tooltip>
          </Stack>
        </Stack>

        <ErrosAlert
          isError={isError}
          messages={messages}
          setshowSyncModal={setshowSyncModal}
          setisError={setisError}
          setMessages={setMessages}
          handleButtonClick={handleButtonClick}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackBarOpen}
          autoHideDuration={autoHideDuration}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>

        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
                  <Tab label="CUSTOMERS" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: 0 }}>
                {RenderCustomers()}
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
