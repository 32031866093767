import React,{ useState } from "react";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { NEW_ARTICLES } from "../../../../utils/constants";

const MileageReport = ({
  register,
  errors,
  editData,
  isFileUploaded,
  setValue,
  setOpenModal,
  isMileageReportTaxFree,
  t,
}) => {
  const { key, label, data } = editData?.item;
  const language = localStorage.getItem("i18nextLng");
  const fileName = (isFileUploaded && data?.uploadFile?.[0]?.name) || data?.fileName;
  const [fileSize, setFileSize] = useState(null);

  return (
    <>
      <div className="form-row mb-3">
        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="isMileageReportTaxFree" className="text-muted small">
            {t("Is the {{label}} free of tax?", {
              label
            })}
          </label>

          <div className="d-flex align-items-center">
            <div className="form-check form-check-inline">
              <input
                className={`form-check-input change_radio_bg ${errors.isMileageReportTaxFree ? "is-invalid" : ""
                  }`}
                type="radio"
                {...register("isMileageReportTaxFree", {
                  required: true,
                })}
                value="1"
              />
              <label
                className="form-check-label text-muted"
                htmlFor="taxFreeYes"
              >
                {t("Yes")}
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className={`form-check-input change_radio_bg ${errors.isMileageReportTaxFree ? "is-invalid" : ""
                  }`}
                type="radio"
                {...register("isMileageReportTaxFree", {
                  required: true,
                })}
                value="0"
              />
              <label
                className="form-check-label text-muted"
                htmlFor="taxFreeNo"
              >
                {t("No")}
              </label>
            </div>
          </div>
        </div>

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="uploadFile" className="text-muted small">
            {`Upload File (${t("PDF files up to 1 MB only")})`}
          </label>

          {/* if file is uploaded */}
          {fileName ? (
            <div className="mb-2 d-flex align-items-center flex-wrap">
              <span className="text-success">
                {fileName}
              </span>
              <MdDelete
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setValue("uploadFile", null);
                  setValue("fileName", null);
                  setOpenModal((prev) => ({
                    ...prev,
                    item: {
                      ...prev.item,
                      data: {
                        ...prev.item.data,
                        uploadFile: null,
                        fileName: null,
                      },
                    },
                  }));
                }}
              />
            </div>
          ) : (
            // if file is not uploaded
            <input
              type="file"
              accept=".pdf"
              className={`form-control-file p-2 rounded border ${errors.uploadFile ? "border-danger" : ""
                }`}

              {...register("uploadFile", {
                required:
                  (key === NEW_ARTICLES.TRAVEL || key === NEW_ARTICLES.MATERIAL_VAT) &&
                    !isFileUploaded
                    ? true
                    : "",
                validate: (value) => {
                  if (value && value[0]) {
                    const file = value[0];
                    const isPDF = file?.type === "application/pdf";
                    const sizeInMB = file.size / (1024 * 1024);
                    setFileSize(sizeInMB.toFixed(2));
                    if (!isPDF) {
                      toast.error(t("Please upload a valid PDF file."));
                      return "Invalid file type.";
                    }
                    if(sizeInMB > 1){
                      toast.error(t("Please select a file up to 1 MB in size."));
                      return "Invalid file size.";
                    }
                    setValue("fileName", value[0].name);
                  }
                  return true;
                },
              })}
            />
          )}
        </div>

        {(!isFileUploaded && isMileageReportTaxFree) ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="isTaxFreeArticle" className="text-muted small">
              {t("Do you want to show the {{label}} on the invoice?", {
                label
              })}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileageArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileageArticleShowInInvoice", {
                    required: true,
                  })}
                  value="1"
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="showYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileageArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileageArticleShowInInvoice", {
                    required: true,
                  })}
                  value="0"
                />
                <label className="form-check-label text-muted" htmlFor="showNo">
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="showPdfToCustomer" className="text-muted small">
              {t("Do you want to show the PDF to the customer?")}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileagePdfShowToCustomer ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileagePdfShowToCustomer", {
                    required: isFileUploaded && true,
                  })}
                  value="1"
                />
                <label
                  className={`form-check-label ${!errors.isMileagePdfShowToCustomer && "text-muted"
                    } `}
                  htmlFor="pdfShowYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileagePdfShowToCustomer ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileagePdfShowToCustomer", {
                    required: isFileUploaded && true,
                  })}
                  value="0"
                />
                <label
                  className={`form-check-label ${!errors.isMileagePdfShowToCustomer && "text-muted"
                    }`}
                  htmlFor="pdfShowNo"
                >
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="form-row mb-3">
        {isFileUploaded && isMileageReportTaxFree ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="isTaxFreeArticle" className="text-muted small">
              {t("Do you want to show the {{label}} on the invoice?", {
                label
              })}
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileageArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileageArticleShowInInvoice", {
                    required: true,
                  })}
                  value="1"
                />
                <label
                  className="form-check-label text-muted"
                  htmlFor="showYes"
                >
                  {t("Yes")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className={`form-check-input change_radio_bg ${errors.isMileageArticleShowInInvoice ? "is-invalid" : ""
                    }`}
                  type="radio"
                  {...register("isMileageArticleShowInInvoice", {
                    required: true,
                  })}
                  value="0"
                />
                <label className="form-check-label text-muted" htmlFor="showNo">
                  {t("No")}
                </label>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="travelDate" className="text-muted small">
            {t("Travel Date")}
          </label>
          <input
            type="date"
            className={`form-control form-control-lg mb-3 ${errors.travelDate ? "is-invalid" : ""
              }`}
            {...register("travelDate", { required: true })}
            pattern="\d{4}-\d{2}-\d{2}"
          />
        </div>

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="distance" className="text-muted small">
            {t("Distance")} (in km)
          </label>
          <input
            type="number"
            className={`form-control form-control-lg mb-3 ${errors.distance ? "is-invalid" : ""
              }`}
            {...register("distance", {
              required: true,
              min: { value: 0 },
            })}
            step="0.1"
          />
        </div>

        {!isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="reasonForTrip" className="text-muted small">
              {t("Reason For Trip")}
            </label>
            <input
              type="text"
              className={`form-control form-control-lg mb-3 ${errors.reasonForTrip ? "is-invalid" : ""
                }`}
              {...register("reasonForTrip", {
                required: isMileageReportTaxFree && true,
              })}
              placeholder={t("Enter reason")}
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="form-row mb-3">
        {isFileUploaded ? (
          <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
            <label htmlFor="reasonForTrip" className="text-muted small">
              {t("Reason For Trip")}
            </label>
            <input
              type="text"
              className={`form-control form-control-lg mb-3 ${errors.reasonForTrip ? "is-invalid" : ""
                }`}
              {...register("reasonForTrip", {
                required: isMileageReportTaxFree && true,
              })}
              placeholder={t("Enter reason")}
            />
          </div>
        ) : (
          ""
        )}

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="carRegistrationNo" className="text-muted small">
            {t("Car Registration No.")}
          </label>
          <input
            type="text"
            className={`form-control form-control-lg mb-3 ${errors.carRegistrationNo ? "is-invalid" : ""
              }`}
            {...register("carRegistrationNo", {
              required: isMileageReportTaxFree && true,
            })}
            placeholder={t("Enter registration no.")}
          />
        </div>

        <div className="form-group col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
          <label htmlFor="tripStartedPlace" className="text-muted small">
            {t("Trip Started Place")}
          </label>
          <input
            type="text"
            className={`form-control form-control-lg mb-3 ${errors.tripStartedPlace ? "is-invalid" : ""
              }`}
            {...register("tripStartedPlace", {
              required: isMileageReportTaxFree && true,
            })}
            placeholder={t("Enter start place")}
          />
        </div>
      </div>
    </>
  );
};

export default MileageReport;
