/* eslint-disable */
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { otherArticleOptions } from '../../../utils/stringUtils';
import axios from 'axios';
import { AiOutlineClose } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { NEW_ARTICLES } from "src/utils/constants";

const OtherArticlesRow = ({
  otherArticleValues,
  isFixed,
  currency,
  setOtherArticleValues,
  showTooltipError,
  setShowTooltipError,
  indexValue,
  handleEdit,
  setOtherArticlesData,
  selectedTab,
  showDates,
  vat,
  currencyGlobalValue
}) => {
  const { t } = useTranslation();

  const [formattedData, setFormattedData] = useState([]);
  const handleCurrencyRate = async () => {
    try {
      const resp = await axios.get('admin/invoice/fortnox-currencies');
      const data = resp?.data?.response;
      const filteredCurr = data?.filter((g) => g?.description === currency);
      if (filteredCurr?.length > 0) {
        let sellRate = filteredCurr[0].globalValue;
        if (filteredCurr[0].description === "SEK") {
          sellRate = 1;
        }
        
        let newUpdatedArticleValues = { ...otherArticleValues };
        Object.keys(newUpdatedArticleValues).forEach((key) => {
          newUpdatedArticleValues[key] = newUpdatedArticleValues[key].map(
            (item) => ({
              ...item,
              value: (parseFloat(item?.value)).toFixed(2),
              valueInSelectedCurrency: (parseFloat(item?.valueInSelectedCurrency) ).toFixed(2),
            })
          );
        });
        setFormattedData(newUpdatedArticleValues);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleCurrencyRate();
  }, [otherArticleValues, currency]);

  return (
    <>
       {otherArticleValues?.[indexValue]?.map((item) => {
              let date;
              if (item.key === NEW_ARTICLES.MILEAGE) {
                date = item?.data?.travelDate || item?.data?.workingDate?.split('T')[0];
              } else if (item?.key === NEW_ARTICLES.ALLOWANCE_OUT) {
                date = item?.data?.tripStartedDateOs && item?.data?.tripEndedDateOs
                  ? `${item?.data?.tripStartedDateOs} - ${item?.data?.tripEndedDateOs}`
                  : `${item?.data?.tripStartedDate?.split('T')[0]} - ${item?.data?.tripEndedDate?.split('T')[0]}`;
              } else if (item?.key === NEW_ARTICLES.ALLOWANCE_IN) {
                date = item?.data?.tripStartedDateIs && item?.data?.tripEndedDateIs
                  ? `${item?.data?.tripStartedDateIs} - ${item?.data?.tripEndedDateIs}`
                  : `${item?.data?.tripStartedDate?.split('T')[0]} - ${item?.data?.tripEndedDate?.split('T')[0]}`;
              }
               else if (item?.key === NEW_ARTICLES.MATERIAL_VAT) {
                date = item?.data?.dateOfPurchase || item?.data?.workingDate?.split('T')[0];
              } else if (item.key === NEW_ARTICLES.TRAVEL) {
                date = item?.data?.travelExpDate || item?.data?.workingDate?.split('T')[0];
              }
              return (
                <div
                  className={`row mobile-scroll flex-row mx-0 No-root-selected align-items-center`}
                  key={item?.id}
                >
                  <div className="desktop-row d-initial align-items-center justify-content-start desktop-1">
                    <div className="d-flex justify-content-start align-items-center">
                      <AiOutlineClose
                        size={24}
                        className="text-primary cursor-pointer close-invoice"
                        onClick={() => {
                          setOtherArticlesData((prevData) => {
                            const updatedData = { ...prevData };
                            const index = selectedTab.index;
                            if (updatedData[index] && updatedData[index].length > 0) {
                              updatedData[index] = updatedData[index].filter(
                                (obj) => obj.id !== item?.id
                              );
                              if (updatedData[index].length === 0) {
                                delete updatedData[index];
                              }
                            }
                            return updatedData;
                          });
                        }}
                      />
      
                      <h6
                        className="m-0 ml-2"
                        style={{
                          color: showDates !== "true" && "#b0b0b0",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {date}
                      </h6>
                    </div>
                  </div>
                  <div className="desktop-row d-flex justify-content-between align-items-start desktop-2 otherarticles-wrap-content">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <Select
                        id="articleSelect"
                        isDisabled={true}
                        className="ml-0 w-100"
                        labelKey="description"
                        menuPortalTarget={document.querySelector("body")}
                        value={otherArticleOptions.find(
                          (data) => data?.key === item.key
                        )}
                        options={otherArticleOptions?.map((item) => ({
                          ...item,
                          value: item.label,
                        }))}
                        getOptionLabel={(option) => t(option?.label)}
                      />
                    </div>
                  </div>

                   <div className="desktop-row row d-flex align-items-center justify-content-start desktop-3">
                    <div className="col-lg-9 col-sm-12 ghd fld_responsive row mx-0">
                      <div className="col pl-0 d-flex align-items-center mobile-inline">
                        <OverlayTrigger
                          show={showTooltipError.negativeAmount}
                          placement="top"
                          overlay={
                            <Popover
                              id={`popover-positioned-top`}
                              style={{ margin: 0 }}
                            >
                              <Popover.Header className="text-danger" as="h3">
                                {showTooltipError.negativeAmount &&
                                  t("Amount value can't be Negative")}
                              </Popover.Header>
                            </Popover>
                          }
                        >
                          <input
                            style={{ background: "#F2F2F2" }}
                            className="form-control w-100 aa no-arrows"
                            type="number"
                            step="any"
                            value={(
                             (Number(item?.data?.amount)) 
                             || (Number(item?.valueInSelectedCurrency))
                          ).toFixed(2)}                    
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          
                            placeholder={currency}
                            disabled={true}
                            min="0"
                            onInput={(e) => {
                              if (e.target.value < 0) {
                                e.target.value = "";
                                setShowTooltipError({
                                  negativeAmount: true,
                                });
                                setTimeout(() => {
                                  setShowTooltipError({
                                    negativeAmount: false,
                                  });
                                }, 3000);
                              } else if (e.target.value === 0) {
                                e.target.value = "";
                              } else {
                                const inputValue = e.target.value;
                                const maxLength = 16;
      
                                if (inputValue?.length > maxLength) {
                                  e.target.value = inputValue.slice(0, maxLength);
                                }
      
                                if (parseFloat(inputValue) <= 0) {
                                  e.target.value = "";
                                }
      
                                const key = item?.key;
                                setOtherArticleValues((prevState) => ({
                                  ...prevState,
                                  [indexValue]: prevState[indexValue].map((item) => {
                                    if (item.key === key) {
                                      return { ...item, value: Number(e.target.value) * Number(currencyGlobalValue), valueInSelectedCurrency : Number(e.target.value) };
                                    }
                                    return item;
                                  }),
                                }));
                              }
                            }}
                          />
                        </OverlayTrigger>
                        <p className="m-0 ml-1">{currency}</p>
                      </div>
                    </div>
      
                    <div className="col-lg-3 d-flex align-items-center justify-content-between">
                      <p className="col-4 m-0 text-right curency nw_rpns mobile-inline other-articles-wrap">
                        {isFixed
                          ? "Fixed Price"
                          : Number(
                            item?.data?.amountInSek ? item?.data?.amountInSek : item?.value 
                            ).toFixed(2)}
                        {isFixed ? "" : <span className="ml-1">SEK</span>}
                        <span className="ml-1 small">
                          {item?.data?.vat != null 
                            ? `(${item?.data?.vat}%) ${t("VAT")}`
                            : `(${vat}%) ${t("VAT")}`}
                        </span>{" "}
                        <a
                          className="btn btn-link"
                          onClick={() => handleEdit(item)}
                          aria-label="Edit"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={t("Edit")}
                        >
                          <MdModeEditOutline />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
    </>
  );
};

export default memo(OtherArticlesRow);