/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Checkbox,
  Container,
  IconButton,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import axios from 'axios';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { pensionDropOptions } from '../../../utils/commonFunc';  
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import { PayoutFilter } from '../../../components/DateFilter';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import DownloadPdf from '../holiday-savings/DownloadPdf';

const TABLE_HEAD = [
  { id: 'userId', label: 'Employee Id', alignRight: false },
  { id: 'userName', label: 'Employee', alignRight: false },
  { id: 'invoiceId', label: 'Email', alignRight: false },
  { id: 'amount', label: 'SSN/DOB', alignRight: false },
  { id: 'totalPensionAmount', label: 'Amount', alignRight: false },
];

const PensionSavings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pensionSavings, setPensionSavings] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdDate');
  const [tabs, settabs] = useState('1');
  const [selectedRow, setSelectedRow] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);

  const [downloadSelectedRow, setdownloadSelectedRow] = useState([]);
  const [toggleDownload, settoggleDownload] = useState(false);
  const [SelectedFileType, setSelectedFileType] = useState('');
  const [searchDropValue, setSearchDropValue] = useState();

  // const handleChange = (event, newValue) => {
  //   settabs(newValue);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      pensionSavings,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      pensionSavings
    );
    setPensionSavings(filteredRows);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const getPensionId = (event, id) => {
    if (id) {
      if (!event.target.closest('input[type="checkbox"]')) {
        localStorage.setItem('pensionId', id);
        navigate(`/dashboard/pension-savings/${id}`);
      }
    }
  };

  const getPensionIdItem = localStorage.getItem('pensionId');
  const [searchQuery, setSearchQuery] = useState(getPensionIdItem !== null ? getPensionIdItem : '');
  const [getDate, setDate] = useState({
    from: '',
    to: '',
  });

  const [filteredMonth, setFilteredMonth] = useState('');
  const [filteredYear, setFilteredYear] = useState('');

  const getPensionSavings = async () => {
    try {
      let URL;
      if (searchDropValue && searchQuery) {
      URL = `/admin/adminsalary/total-pension-amount?PageNumber=${
        page + 1
      }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
    }else{
      URL=`/admin/adminsalary/total-pension-amount?PageNumber=${
        page + 1
      }&PageSize=${rowsPerPage}&searchQuery=${searchQuery}`
    }
      if (getDate?.from && getDate?.to) {
        URL += `&fromDate=${getDate?.from}&toDate=${getDate?.to}`;
      } else if (!filteredMonth && filteredYear) {
        URL += `&year=${filteredYear}-01-01`;
      } else if (filteredMonth && filteredYear) {
        const firstDateOfMonth = new Date(filteredYear, filteredMonth - 1, 1);
        const finalMonth = firstDateOfMonth.toISOString()?.split('T')[0];
        URL += `&Month=${finalMonth}`;
      }
      setLoading(true);
      const result = await axios.get(URL);
      if (result.status === 200) {
        setLoading(false);
        setPensionSavings(result.data.response);
        selectAllClick(isAllSelected, result?.data.response);
        setTotalCount(result.data.totalCount);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const handleChangeDate = (e, type) => {
    if (type === 'month' && filteredYear === '') {
      return toast.error('Please select year before choosing month');
    }
    if (type === 'month') {
      setFilteredMonth(e.target.value);
      setDate({ from: '', to: '' });
      setSearchQuery('');
    } else {
      setFilteredYear(e.target.value);
      setDate({ from: '', to: '' });
      setSearchQuery('');
    }
  };

  // Selected Row Start
  const selectAllClick = (isSelected, filteredRows) => {
    if (isSelected) {
      const newSelecteds = filteredRows?.map((n) => n?.users?.userId);
      setSelectedRow(newSelecteds);
      return;
    }
    setSelectedRow([]);
  };

  const handleSingalSelection = (event, id) => {
    const selectedIndex = selectedRow.indexOf(id);
    let newSelectedRow = [];
    if (selectedIndex === -1) {
      newSelectedRow = newSelectedRow.concat(selectedRow, id);
    } else if (selectedIndex === 0) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(1));
    } else if (selectedIndex === selectedRow.length - 1) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedRow = newSelectedRow.concat(selectedRow.slice(0, selectedIndex), selectedRow.slice(selectedIndex + 1));
    }
    setSelectedRow(newSelectedRow);
    setAllSelected(newSelectedRow.length === pensionSavings.length);
  };

  const handleAllSelectRow = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, pensionSavings);
  };

  // Generate XML File Start
  const handleClearData = () => {
    localStorage.removeItem('pensionId');
    setAllSelected(false);
    setSelectedRow([]);
    setFilteredMonth('');
    setFilteredYear('');
    setDate({ from: '', to: '' });
    setSearchQuery('');
  };

  // Effects
  useEffect(() => {
    if (selectedRow) {
      const filteredData = pensionSavings.filter((item) => selectedRow?.includes(item?.users?.userId));
      setdownloadSelectedRow(filteredData);
    }
  }, [selectedRow]);

  useEffect(() => {
    getPensionSavings();
  }, [searchQuery, filteredMonth, filteredYear, getDate, page, rowsPerPage]);

  const handleSelect = (e) => {
    const value = e.target.value;
    setSelectedFileType(value);
    if (value) {
      settoggleDownload(true);
    } else {
      settoggleDownload(false);
    }
  };

  return (
    <Page title={tabs === '1' ? 'Pension Savings' : 'Calendar'}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Pension Savings
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {selectedRow && selectedRow?.length > 0 && (
              <Typography component="div" variant="subtitle1">
                {selectedRow?.length} Selected
                <select className="select_filetype" onChange={handleSelect} value={SelectedFileType}>
                  <option disabled value="">
                    Select file type
                  </option>
                  <option value="XLS">Excel</option>
                  <option value="PDF">PDF</option>
                </select>
              </Typography>
            )}
          </Stack>
        </Stack>

        <TabContext value={tabs}>
          {loading && <Loader />}

          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Pension Savings" value="1" />
          </TabList>
        </Box> */}

          <TabPanel value="1" sx={{ padding: 0, margin: '10px 0 0 20px' }}>
            <TableContainer component={Paper}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <div className="d-flex holiday_filter">
                  <ListToolbar
                    searchQuery={searchQuery}
                    onFilter={handleFilter}
                    dropOptions={pensionDropOptions}
                    searchDropValue={searchDropValue}
                    setSearchDropValue={setSearchDropValue}
                  />
                  <div className="w-75">
                    <PayoutFilter
                      getDate={getDate}
                      setDate={setDate}
                      filteredMonth={filteredMonth}
                      setFilteredMonth={setFilteredMonth}
                      filteredYear={filteredYear}
                      setFilteredYear={setFilteredYear}
                      handleChangeDate={handleChangeDate}
                      handleClearData={handleClearData}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                    />
                  </div>
                </div>
              </Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleAllSelectRow}
                  hideCheckboxColumnHeader="true"
                  hideForOthers
                  isAllSelected={isAllSelected}
                />
                <TableBody>
                  {pensionSavings?.length ? (
                    pensionSavings?.map((item) => {
                      const isItemSelected = selectedRow?.indexOf(item?.users?.userId) !== -1;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={(event) => getPensionId(event, item?.users?.userId)}
                          key={item?.users?.userId}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleSingalSelection(event, item?.users?.userId)}
                          />
                          <TableCell>{item?.users?.userId}</TableCell>
                          <TableCell>
                            {item?.users?.firstName} {item?.users?.lastName}
                          </TableCell>
                          <TableCell>{item?.users?.email}</TableCell>
                          <TableCell>{item?.users?.ssn || item?.users?.dateOfBirth} </TableCell>
                          <TableCell>{item?.totalPensionAmount?.toFixed(2)}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow className="text-center">
                      <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                        No record found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[50, 250, 500]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
        </TabContext>
      </Container>

      <DownloadPdf
        downloadSelectedRow={downloadSelectedRow}
        toggleDownload={toggleDownload}
        settoggleDownload={settoggleDownload}
        filteredYear={filteredYear}
        filteredMonth={filteredMonth}
        getDate={getDate}
        SelectedFileType={SelectedFileType}
        setSelectedFileType={setSelectedFileType}
      />
    </Page>
  );
};

export default PensionSavings;
