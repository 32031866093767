import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

const SentryUserProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (user) {
      // Set user in Sentry when authenticated
      Sentry.setUser({
        id: user.userId,
        email: user.email, // displayed in sentry dashboard
        username: user.firstName || "Username unavailable", // no username field in our user object
      });
    } else {
      // Clear user information in Sentry when logged out
      Sentry.setUser(null);
    }
  }, [user]);

  return children;
};

export default SentryUserProvider;
