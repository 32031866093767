// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import store from './redux/store';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import "./Sentry";
import SentryUserProvider from "./providers/SentryUserProvider";
import ErrorFallback from "./layouts/ErrorFallback";
import "./translation/i18n";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
     <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <Provider store={store}>
        <SentryUserProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SentryUserProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
