import React from "react";

const AddIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    className="plus-icon-svg"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {/* border */}
    <circle className="circle-bg" cx="12" cy="12" r="10" />
    {/* Vertical line */}
    <line className="plus-line" x1="12" y1="8" x2="12" y2="16" />
    {/* Horizontal line */}
    <line className="plus-line" x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

export default AddIcon;
