/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { otherArticleOptions } from '../../../utils/stringUtils';

import AddIcon from 'src/components/AddIcon';
import { useState } from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { toast } from "react-toastify";

const OtherArticlesOptionsUI = ({
  dates,
  register,
  indexValue,
  watch,
  setError,
  setShow,
  show,
  handleSeeCalculations,
  setShowSubmit,
  totalHours,
  setValue,
  handleOtherArticleValue,
  otherArticleValues,
  anyArrayHasValue,
  hasWorkingHours,
}) => {
  const { t } = useTranslation();
  const [pdfError, setPdfError] = useState(null);
  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    }
    const halfLength = Math.floor((maxLength - 3) / 2); // Account for "..."
    const start = fileName.slice(0, halfLength);
    const end = fileName.slice(-halfLength);
    return `${start}...${end}`;
  };
  const [selectedTab, setSelectedTab] = useState({
    index: 0,
    date: "",
    validateUnit: false,
    validateAmount: false,
    validatePdfFile: false,
    validateArticle: false,
    validateCalender: false,
  });
  const invoiceFile = watch("invoiceFile");
  const triggerFileInputClick = () => {
    document.getElementById("uploadFileInput").click(); // Trigger the hidden file input click
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
<div className='other-artical-ui'>
          <div className="d-flex justify-content-between ml-2">
            <div className="left-side w-50">
              {otherArticleOptions?.slice(0, 3).map((item, index) => (
                <div
                  className="mt-2 mb-0 d-flex justify-content-between mb-1"
                  key={item?.id}
                >
                  <div className="checkbox-wrapper-37 d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={otherArticleValues[indexValue]?.some((obj) => {
                        return obj.key === item?.key;
                      })}
                      id={item?.key}
                      onChange={(e) => handleOtherArticleValue(e, item)}
                    />
                    <label htmlFor={item?.key} className="terms-label mr-2">
                      <AddIcon />
                    </label>
                    <span className="mb-1">{t(item?.label)}</span>
                  </div>
                </div>
              ))}
            </div>

            <div className="right-side w-50">
              {otherArticleOptions?.slice(3).map((item, index) => (
                <div
                  className="text-right mt-2 mb-0 d-flex justify-content-between mb-1"
                  key={item?.id}
                >
                  <div className="checkbox-wrapper-37 d-flex">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={otherArticleValues[indexValue]?.some(
                        (obj) =>{ return obj.key === item?.key
                      })}
                      id={item?.key}
                      onChange={(e) => handleOtherArticleValue(e, item)}
                    />
                    <label htmlFor={item?.key} className="terms-label mr-2">
                      <AddIcon />
                    </label>
                    <span className="mb-1">{t(item?.label)}</span>
                  </div>
                </div>
              ))}
              {invoiceFile &&
              ((Array.isArray(invoiceFile[indexValue]) &&
                invoiceFile[indexValue]?.fileContent !== "" &&
                invoiceFile[indexValue]?.length > 0) ||
                (Object.keys(invoiceFile[indexValue] || {}).length > 0 &&
                  invoiceFile[indexValue]?.fileContent !== "")) ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ color: "green", marginLeft: "26px" }}>
                    {invoiceFile[indexValue]?.length > 0
                      ? truncateFileName(invoiceFile[indexValue][0]?.name, 22)
                      : truncateFileName(invoiceFile[indexValue]?.fileName, 22)}
                  </span>

                  <span className="color-bg ml-2 h-8">
                    <RiDeleteBin6Line
                      className="cursor-pointer"
                      onClick={() => {
                        watch(`invoiceFile[${indexValue}]`);
                        setValue(`invoiceFile[${indexValue}]`, []);
                        setPdfError((prevErrors) => ({
                          ...prevErrors,
                          [indexValue]: {
                            type: "",
                            message: "",
                          },
                        }));
                      }}
                      size={24}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={t("Delete")}
                    />
                  </span>
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        pdfError &&
                        pdfError[selectedTab.index]?.type === "success"
                          ? "#096c09"
                          : "rgb(211 45 45)",
                      marginLeft: "5px",
                    }}
                  >
                  </span>
                </div>
              ) : (
                <div
                  onClick={triggerFileInputClick}
                  className="upload-container "
                >
                  <label className="terms-label mr-2">
                    <AddIcon />
                  </label>
                  <span className="" style={{}}>
                    {t("Upload PDF File(Up to 1 MB only)")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          {(hasWorkingHours || anyArrayHasValue) && (
            <p className="text-right text-primary mb-0">
              <a
                className="how_calculate_link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleSeeCalculations(indexValue);
                  setShowSubmit(false);
                }}
              >
                {t("See how we calculated")}?
              </a>
            </p>
          )}

          <p className="text-right w-100 mb-0">
            {t("Total working hours")}:{" "}
            {totalHours[indexValue] ? totalHours[indexValue] : 0}
          </p>
        </div>
      </div>
      <input
        id="uploadFileInput"
        style={{ display: "none" }}
        type="file"
        {...register(`invoiceFile[${indexValue}]`, {
          onChange: (e) => {
            const file = e.target.files[0];
            // Check if the file is a PDF
            if (file && file.type !== "application/pdf") {
              setError("invoiceFile", {
                type: "pdf",
                message: "Only PDFs are valid.",
              });
            } else {
              // Check if the file size is within the limit (5MB)
              if (file && file.size > 1 * 1024 * 1024) {
                e.target.value = null;
                toast.error(t("Please select a file up to 1 MB in size."));
                return "Invalid file type.";
              } else {
                setPdfError((prevErrors) => ({
                  ...prevErrors,
                  [indexValue]: {
                    type: "success",
                    message: <FaRegCheckCircle />,
                  },
                }));
                setShow({
                  ...show,
                  PDFData: watch("invoiceFile"),
                });
              }
            }
          },
        })}
        accept="application/pdf"
      ></input>
    </div>
  );
};

export default OtherArticlesOptionsUI;
