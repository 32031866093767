/* eslint-disable */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useDispatch, useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { toast } from 'react-toastify';
import countries from 'countries-list';
import Form from 'react-bootstrap/Form';

// material Components
import { Card, Table, Box, TextField, Modal, Button, Tooltip, Alert, Select, Checkbox, Stack } from '@mui/material';
import { TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination } from '@mui/material';
import { IconButton, Snackbar, OutlinedInput, FormControl, InputLabel } from '@mui/material';
import { MenuItem, ListItemText, Chip, TableHead } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';

// React Icons
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import EmailIcon from '@mui/icons-material/Email';
import NoteIcon from '@mui/icons-material/Note';
import HistoryIcon from '@mui/icons-material/History';

// Redux slices
import { fetchGlobalData } from 'src/redux/globalData/globalDataSlice';
import { fetchEmailTemplate } from '../../../redux/auth/authSlice';

// components
import { formatDate, getModalBoxStyle, userDropOptions } from '../../../utils/commonFunc';
import TooltipWithCopy from '../../../components/TooltipWithCopy';
import { ListHead, ListToolbar } from '../../../sections/@dashboard/table';
import SearchNotFound from '../../../components/SearchNotFound';
import HoursModal from '../../../components/MonthlyHoursModal';
import { MultipleUsersPDF } from './MultipleUsersPDF';
import Scrollbar from '../../../components/Scrollbar';
import Page from '../../../components/Page';
import Loader from '../../../components/Loader';
import Iconify from '../../../components/Iconify';
import PermanentDeactivatedUser from './PermanentDeactivatedUser';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'userId', label: 'Id', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  {
    id: 'totalInvoiceAmount',
    label: 'Total Invoice Amount',
    alignRight: false,
  },
  { id: 'invoiceCount', label: 'Invoice Count', alignRight: false },
  { id: 'createdDate', label: 'Created Date', alignRight: false },
  { id: 'profession', label: 'Profession', alignRight: false },
  { id: 'incomeTax', label: 'Tax', alignRight: false },
  { id: 'fakturaFee', label: 'Invozio fee', alignRight: false },
  { id: 'monthlyHours', label: 'Monthly Hours', alignRight: false },
  { id: 'bankDetails', label: 'Bank Details', alignRight: false },
  { id: 'invoice', label: 'Invoice' },
  { id: 'actions', label: 'Actions' },
];

const deactivatedHead = [
  { id: 'userId', label: 'Id', alignRight: false },
  { id: 'firstName', label: 'First Name', alignRight: false },
  { id: 'email', label: 'E-email', alignRight: false },
  { id: 'ssn', label: 'SSN', alignRight: false },
  { id: 'createdDate', label: 'Created Date', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  // if (query) {
  //   const searchRegex = new RegExp(escapeRegExp(query), "i");
  //   return filter(orgArray, (_row) =>
  //     Object.keys(_row).some((field) => {
  //       if (_row[field]) return searchRegex.test(_row[field].toString());
  //       return false;
  //     })
  //   );
  // }

  return stabilizedThis?.map((el) => el[0]);
}

export default function Users() {
  const getselectedUser = localStorage.getItem('selected_invoice');
  const [selectedOption, setSelectedOption] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedTabValue, setSelectedTabValue] = useState('2');
  const [personName, setPersonName] = useState([]);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState({
    bankDetails: false,
    sandEmail: false,
  });
  const [toggleNote, setToggleNote] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(3000);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertStatus, setAlertStatus] = useState('success');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdDate');
  const [searchQuery, setSearchQuery] = useState(getselectedUser !== null ? getselectedUser : '');
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [emailType, setemailType] = useState(null);
  const currentYear = new Date().getUTCFullYear();
  const [toggleHourModal, settoggleHourModal] = useState(false);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [getUserId, setGetUserId] = useState(0);

  const [incomeData, setincomeData] = useState({
    values: {},
    statusCode: null,
  });

  const [showUserDetails, setShowUserDetails] = useState(null);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [isAllSelected, setAllSelected] = useState(false);
  const [templateData, setTemplateData] = useState({
    Subject: '',
    content: '<p></p>',
    templateFromName: '',
  });

  const [commentList, setcommentList] = useState([]);
  const [chooseBankName, setChooseBankName] = useState('');
  const [bankName, setBankName] = useState('');
  const [activateModal, setActivateModal] = useState({
    id: null,
    show: false,
    isActive: 'false',
  });
  const [permanentDeactivate, setpermanentDeactivate] = useState({
    userId: null,
    show: false,
  });
  const [accidentalReactivate, setaccidentalReactivate] = useState({
    userId: null,
    show: false,
  });
  const [notesContent, setNotesContent] = useState('');
  const [reasonForDeactivated, setreasonForDeactivated] = useState('');
  const [isReasonEmpty, setisReasonEmpty] = useState(false);
  const [isNotesContentEmpty, setIsNotesContentEmpty] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [searchDropValue, setSearchDropValue] = useState();
  const [isSendLink, setisSendLink] = useState({
    id: null,
    show: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { emailTemplate, isLoading } = useSelector((data) => data.auth);
  const banksData = useSelector((state) => state?.globalData?.data?.banks);

  const {
    reset,
    watch,
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const ssnNumber = watch('ssn');
  const years = Array(currentYear - (currentYear - (currentYear - 2021)))
    .fill('')
    ?.map((v, idx) => currentYear - idx);

  const {
    reset: resetForm2,
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
  } = useForm();

  const {
    reset: resetForm3,
    register: registerForm3,
    handleSubmit: handleSubmitForm3,
    formState: { errors: errorsForm3 },
  } = useForm();

  useEffect(() => {
    dispatch(fetchEmailTemplate());
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchUsers();
    }, 600);
    return () => clearTimeout(timeoutId);
  }, [page, rowsPerPage, selectedTabValue, searchQuery, searchDropValue]);

  const { search } = useLocation();

  useEffect(() => {
    dispatch(fetchGlobalData());
  }, [dispatch]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      let URL;
      if (searchDropValue && searchQuery) {
        URL = `/admin/user?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}&SearchColumn=${searchDropValue}`;
      } else {
        URL = `/admin/user?PageSize=${rowsPerPage}&PageNumber=${
          page + 1
        }&active=${selectedTabValue}&searchQuery=${searchQuery}`;
      }

      const res = await axios.get(URL);
      if (res?.status === 200) {
        setLoading(false);
        const users = res?.data?.response?.map((ele) => ({
          ...ele,
          isEdit: false,
        }));

        setOriginalUsers(users);

        const userId = new URLSearchParams(search).get('notificationVal');

        const filteredRows = applySortFilter(users, getComparator(order, orderBy), users);
        const notificationFilteredList = filteredRows?.map((item) => ({
          ...item,
          isSelected: item.userId === Number(userId),
        }));
        selectAllClick(isAllSelected, notificationFilteredList);
        setUsers(notificationFilteredList);
        setTotalCount(res.data.totalCount);

        setFilteredUsers(notificationFilteredList);
      }
    } catch (err) {
      if (err?.response?.data?.message === 'Something went wrong please try again after sometime.') {
        fetchUsers();
      } else {
        setUsers([]);
        setLoading(false);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const filteredRows = applySortFilter(
      filteredUsers,
      getComparator(isAsc ? 'desc' : 'asc', orderBy),
      event.target.value,
      originalUsers
    );
    setFilteredUsers(filteredRows);
  };

  const selectAllClick = (isSelected, filteredUsers) => {
    if (isSelected) {
      const newSelecteds = filteredUsers?.map((n) => n.userId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    setAllSelected(event.target.checked);
    selectAllClick(event.target.checked, filteredUsers);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setSelected(selected);
    setPage(0);
  };

  const handleFilter = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDelete = async () => {
    await deleteUsers();
  };

  const deleteUsers = async () => {
    try {
      setLoading(true);
      await axios.delete('/admin/user', { data: selected }).then((res) => {
        const isDeletedCount = res?.data?.response?.isDeleted;
        if (isDeletedCount > 0) {
          setAlertMessage(
            `${
              isDeletedCount === res?.data?.totalCount
                ? `Selected ${isDeletedCount > 1 ? 'users' : 'user'} has been deleted successfully.`
                : `${isDeletedCount} ${isDeletedCount > 1 ? 'users' : 'user'} out of ${
                    res?.data?.totalCount
                  } has been deleted successfully.`
            }`
          );
          setAlertStatus('success');
        } else {
          setAlertMessage(`Selected ${isDeletedCount > 1 ? 'users' : 'user'} can't been deleted.`);
          setAlertStatus('error');
        }
        setIsSnackBarOpen(true);
        fetchUsers();
      });
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
      setSelected([]);
    }
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const isNotFound = filteredUsers?.length === 0;

  const handleRowClick = (event, id) => {
    navigate(`/dashboard/user/invoices/${id}`);
  };

  const activateOrDeactivateUser = async (userId, type, checkUser) => {
    setLoading(true);
    try {
      if (checkUser?.isBelowEighteen && checkUser?.isParentalEmailReceived === false) {
        const response = await axios.put(`/admin/user/activate-below-eighteen-user/${userId}`);
        if (response?.data?.statusCode === 200) {
          setAlertMessage(`User is activated successfully.`);
          await fetchUsers();
        }
      } else {
        await axios.put(`/admin/user/${userId}?isActive=${type}`).then(async () => {
          setAlertMessage(`UserId #${userId} has been ${type === 'true' ? 'Activated' : 'Deactivated'} successfully.`);
          setAlertStatus('success');
          setAutoHideDuration(2000);
          setIsSnackBarOpen(true);
          await fetchUsers();
          setActivateModal({ show: false });
        });
      }
    } catch (err) {
      setActivateModal({ show: false });
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setActivateModal({ show: false });
      setLoading(false);
    }
  };

  const getUserDetails = (id) => {
    const user = users.find((users) => users.userId === id);
    setShowUserDetails(user);
    setShowEditModal(true);
    reset({
      userAccountType: (user.accountType === 1 && 'Private Account') || (user.accountType === 2 && 'Business Account'),
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      ssn: user?.ssn,
      country: user?.country,
      phoneNo: user?.phoneNo,
      dateOfBirth: user?.dateOfBirth,
      city: user?.city,
      address: user?.address,
      zipCode: user?.zipCode,
      incomeTax: user?.incomeTax,
      fakturaFee: user?.fakturaFee,
      organisation: user?.organisation,
      profession: user?.profession,
      accountType: user?.accountType,
    });
  };

  const editUserDetails = async (values) => {
    try {
      setLoading(true);
      await axios.put('/admin/user/edit-employee', {
        ...values,
        id: showUserDetails.userId,
        dateOfBirth: selectedOption === 'DOB' ? values.dateOfBirth : '',
        ssn: selectedOption === 'SSN' ? values.ssn : '',
        accountType: values.userAccountType === 'Private Account' ? 'Contract' : 'Business',
      });
      setShowEditModal(false);
      setAlertMessage('Details has been updated successfully.');
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
      await fetchUsers();
    } catch (err) {
      setAlertMessage(err?.response.data?.message);
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const editBankDetails = async (values) => {
    try {
      setLoading(true);
      const response = await axios.put('/admin/user/update-bankdetails', {
        id: selectedUser.customerSalaryInformation[0]?.id,
        userId: selectedUser.userId,
        bank: values.chooseBankName === 'Other banks' ? values.createBankName : chooseBankName,
        bicNumber: values.bicNumber || '',
        ibanNumber: values.ibanNumber || '',
        accountNumber: values.accountNumber || '',
        clearingNumber: values.clearingNumber || '',
      });
      setBankName('');
      setChooseBankName('');
      resetForm2({ bankDetails: {} });
      setIsOpenModal({ bankDetails: false });
      setAlertMessage(response?.data.message);
      setAlertStatus('success');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
      await fetchUsers();
    } catch (err) {
      setAlertMessage(err?.response?.data?.message);
      setAlertStatus('error');
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const newDetails = [];

  Object.keys(countries.countries).forEach((code) => {
    // if (countries.countries[code].continent === 'EU') {
    newDetails.push({ label: countries.countries[code].name });
    // }
  });

  const handleSnackBarClose = () => {
    setIsSnackBarOpen(false);
  };

  const handleOpenModal = (id) => {
    setIsOpenModal({ bankDetails: true });
    const filteredUser = filteredUsers.find((u) => u.userId === id);

    resetForm2({
      bankDetails: {
        chooseBankName,
        createBankName: filteredUser.customerSalaryInformation[0]?.bank,
        bicNumber: filteredUser.customerSalaryInformation[0]?.bicNumber,
        ibanNumber: filteredUser.customerSalaryInformation[0]?.ibanNumber,
        clearingNumber: filteredUser.customerSalaryInformation[0]?.clearingNumber,
        accountNumber: filteredUser.customerSalaryInformation[0]?.accountNumber,
      },
    });
    setSelectedUser(filteredUser);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const onSendEmail = () => {
    const selectedUsers = users.filter((item) => selected.includes(item.userId));
    setPersonName(selectedUsers?.map((item) => item.email));
    setIsOpenModal({ sandEmail: true });
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      setIsOpenModal(false);
      const response = await axios.post('/adminemailtemplate/email', {
        ...templateData,
        toEmails: personName,
      });
      setAlertStatus('success');
      setAlertMessage(response?.data.response);
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    } catch (err) {
      setAlertStatus('error');
      if (err.response.data) {
        setAlertMessage(err?.response.data?.message);
      } else {
        setAlertMessage(err.message);
      }
      setAutoHideDuration(3000);
      setIsSnackBarOpen(true);
    }
  };

  const handleEmailType = (e) => {
    const value = e.target.value;
    setemailType(value);
  };

  const handleSendAllEmails = async (type) => {
    try {
      if (!templateData?.Subject || !templateData?.templateFromName) {
        return toast.error('Please fill emails fields.');
      }

      setIsOpenModal(false);
      setemailType(null);
      const response = await axios.post(`/adminemailtemplate/send-email-allusers?type=${type}`, {
        ...templateData,
        toEmails: personName,
      });

      if (response?.data?.response) {
        toast.success(response?.data?.response);
      } else {
        toast.error(response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (emailType !== null) {
      if (emailType === 1) {
        handleSendAllEmails(1);
      } else if (emailType === 2) {
        handleSendAllEmails(2);
      } else {
        handleSendAllEmails(3);
      }
    }
  }, [emailType]);

  useEffect(() => {
    if (watch('emailTemplate.selectedTemplate')) {
      const removedDuplicate = [];
      const userData = watch('emailTemplate.selectedTemplate')?.emailTemplateUserList;
      if (selected?.length > 0) {
        const selectedUsers = users.filter((item) => selected.includes(item.userId));
        const allEmails = [
          ...userData?.map((item) => item.signUpUser.email),
          ...selectedUsers?.map((item) => item.email),
        ];
        removedDuplicate.push(...allEmails.filter((item, index) => allEmails.indexOf(item) === index));
      }
      setTemplateData(() => ({
        content: watch('emailTemplate.selectedTemplate')?.html,
        Subject: watch('emailTemplate.selectedTemplate')?.templateSubject,
        templateFromName: watch('emailTemplate.selectedTemplate')?.templateFromName,
      }));
      setPersonName(removedDuplicate?.length > 0 ? removedDuplicate : userData?.map((item) => item?.signUpUser?.email));
    }
  }, [watch('emailTemplate.selectedTemplate')]);

  const handleBankChange = (event) => {
    setChooseBankName(event.target.value);
  };

  // Bank Name select
  useEffect(() => {
    if (Object.keys(selectedUser)?.length > 0) {
      setBankName(Object.keys(selectedUser)?.length > 0 ? selectedUser.customerSalaryInformation[0]?.bank : '');
      setChooseBankName(
        banksData.some((item) => item.description === bankName) || !bankName ? bankName : 'Other banks'
      );
    }
  }, [selectedUser, bankName]);

  // Add Notes
  const addnoteToggle = (id) => {
    setToggleNote(true);
    const filteredUser = filteredUsers.find((u) => u.userId === id);
    resetForm3({
      description: '',
    });
    setSelectedUser(filteredUser);
  };

  const postAddnotes = async () => {
    try {
      if (notesContent === '' || notesContent.trim() === '') {
        setIsNotesContentEmpty(true);
        setNotesContent('');
      } else {
        setIsNotesContentEmpty(false);
        setLoading(true);
        const response = await axios.post('/admin/user/notes', {
          userId: selectedUser?.userId,
          invoiceId: 0,
          description: notesContent,
        });

        if (response?.status === 200) {
          setLoading(false);
          fetchCommentList();
          setNotesContent('');
          toast.success('Notes added successfully');
          fetchUsers();
        } else {
          setLoading(false);
          toast.error('Server Error! Please try again');
        }
      }
    } catch (err) {
      setLoading(false);
      if (err?.response?.data) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error(err?.message);
      }
      setAlertStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const fetchCommentList = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`admin/user/get-notes?userId=${selectedUser?.userId}`);
      setcommentList(res?.data?.response);
    } catch (err) {
      setcommentList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSendLink = async (data) => {
    const { userId } = data?.row;
    try {
      setLoading(true);
      const url = `/admin/user/send-verifymail-to-customer`;
      const response = await axios.post(url, [userId]);
      if (response?.data?.message === 'Success') {
        setAlertMessage('Email sent successfully');
        setAlertStatus('success');
        setIsSnackBarOpen(true);
        setisSendLink({
          show: false,
        });
      } else {
        throw new Error(response?.data?.message || 'Unexpected error');
      }
    } catch (err) {
      setAlertMessage(err?.response?.data?.message || err.message);
      setAlertStatus('error');
      setIsSnackBarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const [toggleuserInfo, settoggleuserInfo] = useState(false);
  const [getDeactivateduserInfo, setgetDeactivateduserInfo] = useState(null);
  const getDeactivatedNotes = async (userId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/admin/user/get-notes?userId=${userId}&IsPermanentDeativatedNote=true`);
      setgetDeactivateduserInfo(response?.data?.response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getUserDetailsInfo = (id) => {
    settoggleuserInfo(true);
    getDeactivatedNotes(id);
  };

  const handlePermanentDeactivateUser = async (e, userId) => {
    e.preventDefault();
    try {
      const trimmedReason = reasonForDeactivated.trim();
      if (trimmedReason === '') {
        setisReasonEmpty(true);
      } else {
        setLoading(true);
        setisReasonEmpty(false);
        const result = await axios.put(`/admin/user/permanent-deactivate-user/${userId}?description=${trimmedReason}`);
        if (result?.data?.statusCode === 200) {
          toast.success('User is deactivated successfully.');
          setpermanentDeactivate(false);
          setLoading(false);
          setreasonForDeactivated('');
          await fetchUsers();
        }
      }
    } catch (error) {
      if (error?.response?.status === 500) {
        toast.error('Fortnox error occurred. Please refresh and try again.');
      } else {
        toast.error(error?.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (toggleNote) {
      fetchCommentList();
    }
  }, [toggleNote, selectedUser]);

  const downloadData = async (userId) => {
    setLoading(true);
    try {
      const filteredUser = filteredUsers.find((u) => u.userId === userId);
      setGetUserId(filteredUser?.userId);

      const res = await axios.get(`admin/adminsalary/yearly-hours?id=${userId}&year=${selectedYear}`);
      if (res?.status === 200) {
        setLoading(false);

        setincomeData({
          values: res?.data?.response,
          statusCode: res?.status,
        });
      } else {
        toast.error('Server Error! Please try again');
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const commentStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: '60%',
    height: '80%',
    // overflow: 'scroll',
    marginBottom: '40',
    p: 3,
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleChangeStatusOfDeactivate = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`admin/user/permanent-reactivate-user/${accidentalReactivate?.userId}`);
      if (response.data?.statusCode === 200) {
        setLoading(false);
        toast.success('User is reactivated successfully');
        setaccidentalReactivate({
          show: false,
        });
        await fetchUsers();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    if (selectedOption === 'SSN') {
      resetField('dateOfBirth', { defaultValue: '' });
    } else if (selectedOption === 'DOB') {
      resetField('ssn', { defaultValue: '' });
    }
  };

  useEffect(() => {
    if (showUserDetails?.dateOfBirth === '' || showUserDetails?.dateOfBirth === null) {
      setSelectedOption('SSN'); // Select SSN
    } else if (showUserDetails?.dateOfBirth && showUserDetails?.ssn) {
      setSelectedOption('SSN');
    } else if (showUserDetails?.ssn === '' || showUserDetails?.ssn === null) {
      setSelectedOption('DOB'); // Select DOB
    }
  }, [showUserDetails]);

  const renderUsers = () => (
    <>
      <ListToolbar
        onDelete={handleDelete}
        onFilter={handleFilter}
        onSendEmail={onSendEmail}
        searchQuery={searchQuery}
        numSelected={selected?.length}
        hideDeleteButton={selectedTabValue !== '3' && 'true'}
        dropOptions={userDropOptions}
        searchDropValue={searchDropValue}
        setSearchDropValue={setSearchDropValue}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table className={`${selectedTabValue === '5' ? '' : 'user-table'}`}>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={selectedTabValue !== '5' ? TABLE_HEAD : deactivatedHead}
              rowCount={users?.length}
              numSelected={selected?.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              hideCheckboxColumnHeader={selectedTabValue === '5' ? true : false}
            />
            <TableBody>
              {(filteredUsers?.length &&
                filteredUsers?.map((row) => {
                  const {
                    userId,
                    firstName,
                    lastName,
                    totalInvoiceAmount,
                    invoiceCount,
                    createdDate,
                    profession,
                    incomeTax,
                    fakturaFee,
                    isEdit,
                    isActive,
                    notes,
                    isPermanentDeactivate,
                  } = row;

                  const isItemSelected = selected.indexOf(userId) !== -1;
                  return (
                    <>
                      {selectedTabValue !== '5' ? (
                        <TableRow
                          key={userId}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          style={{
                            backgroundColor: row?.isSelected ? 'lightgrey' : '',
                            cursor: 'pointer',
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, userId)}
                              onClick={(event) => event.stopPropagation()}
                              size="small"
                            />
                          </TableCell>

                          <TableCell
                            align="left"
                            onClick={(event) => handleRowClick(event, userId)}
                            style={{ fontSize: '13px', padding: '6px' }}
                          >
                            {userId}
                          </TableCell>
                          {firstName?.length >= 9 ? (
                            <Tooltip title={firstName} arrow placement="top">
                              <TableCell
                                align="left"
                                onClick={(event) => {
                                  getUserDetails(userId);
                                  event.stopPropagation();
                                }}
                                style={{ fontSize: '13px', padding: '6px' }}
                              >
                                {firstName?.substring(0, 9).concat('...')}
                              </TableCell>
                            </Tooltip>
                          ) : (
                            <TableCell
                              align="left"
                              className="userColumn"
                              onClick={(event) => {
                                getUserDetails(userId);
                                event.stopPropagation();
                              }}
                            >
                              {firstName}
                            </TableCell>
                          )}

                          <TableCell
                            align="left"
                            className="userColumn"
                            onClick={(event) => handleRowClick(event, userId)}
                          >
                            {totalInvoiceAmount}
                          </TableCell>

                          <TableCell
                            align="left"
                            className="userColumn"
                            onClick={(event) => handleRowClick(event, userId)}
                          >
                            {invoiceCount}
                          </TableCell>

                          <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                            {createdDate?.split('T')[0]}
                          </TableCell>

                          <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                            {profession?.length > 10 ? (
                              <Tooltip title={profession}> {profession.substring(0, 10).concat('...')} </Tooltip>
                            ) : (
                              profession
                            )}
                          </TableCell>

                          <TableCell align="left" style={{ fontSize: '13px', padding: '6px' }}>
                            {incomeTax}
                          </TableCell>

                          <TableCell align="center" style={{ fontSize: '13px', padding: '6px' }}>
                            {fakturaFee}
                          </TableCell>

                          <TableCell align="left" style={{ padding: '6px' }}>
                            <Tooltip title="Hours">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  downloadData(userId);
                                  settoggleHourModal(true);
                                }}
                                style={{
                                  width: 50,
                                  minWidth: '50px',
                                  fontSize: '12px',
                                }}
                              >
                                Hours
                              </Button>
                            </Tooltip>
                          </TableCell>

                          <TableCell align="left" style={{ padding: '6px' }}>
                            <Tooltip title="View">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  handleOpenModal(userId);
                                }}
                                style={{
                                  width: 50,
                                  minWidth: '50px',
                                  fontSize: '12px',
                                }}
                              >
                                View
                              </Button>
                            </Tooltip>
                          </TableCell>

                          <TableCell align="left" style={{ padding: '6px' }}>
                            <Tooltip title="Create Invoice">
                              <Button
                                variant="contained"
                                onClick={() => {
                                  navigate(`/dashboard/invoice-tool/${userId}`);
                                }}
                                style={{
                                  width: 102,
                                  minWidth: '102px',
                                  fontSize: '12px',
                                  padding: '6px 4px',
                                }}
                              >
                                Create Invoice
                              </Button>
                            </Tooltip>
                          </TableCell>

                          <TableCell
                            style={{
                              width: 'auto',
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: isEdit ? 'flex-start' : 'center',
                              flexDirection: 'row',
                              padding: '6px',
                              paddingLeft: '0',
                              marginLeft: '10px',
                            }}
                          >
                            <Tooltip
                              title={isPermanentDeactivate ? 'User is Permanent De-Activate' : 'Permanent De-Activate'}
                            >
                              <Button
                                type="submit"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Permanent De-Activate"
                                variant={'contained'}
                                onClick={() => {
                                  setpermanentDeactivate({
                                    show: true,
                                    userId: userId,
                                  });
                                }}
                                disabled={isPermanentDeactivate}
                                style={{
                                  width: 50,
                                  minWidth: '50px',
                                  fontSize: '12px',
                                  padding: '6px',
                                  marginRight: '12px',
                                }}
                              >
                                <DoDisturbIcon />
                              </Button>
                            </Tooltip>

                            <Tooltip title={!isActive ? 'Activate' : 'Deactivate'}>
                              <Button
                                variant="contained"
                                color={isActive ? 'primary' : 'error'}
                                onClick={() => {
                                  setActivateModal({
                                    userId,
                                    show: true,
                                    isActive: isActive ? 'true' : 'false',
                                    row,
                                  });
                                }}
                                // onClick={() => {
                                //   activateOrDeactivateUser(isActive, userId);
                                // }}
                                style={{
                                  width: 82,
                                  minWidth: '70px',
                                  fontSize: '12px',
                                }}
                              >
                                {isActive ? 'Activated' : 'Deactivated'}
                              </Button>
                            </Tooltip>
                            <Tooltip title="Notes">
                              <Button
                                type="submit"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Notes"
                                variant={notes ? '' : 'contained'}
                                onClick={() => {
                                  addnoteToggle(userId);
                                }}
                                style={{
                                  width: 50,
                                  minWidth: '50px',
                                  fontSize: '12px',
                                  padding: '6px',
                                  marginLeft: '8px',
                                }}
                              >
                                <NoteIcon style={{ color: notes && 'rgb(226,204,97)' }}>NoteIcon</NoteIcon>
                              </Button>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <BorderColorIcon
                                style={{
                                  gap: '7px',
                                  marginLeft: '8px',
                                  color: '#fff',
                                  borderRadius: '100px',
                                  width: '30px',
                                  height: '30px',
                                  padding: '6px',
                                  cursor: 'pointer',
                                  background: !isEdit ? '#62b5e5' : '',
                                }}
                                onClick={(event) => {
                                  getUserDetails(userId);
                                  event.stopPropagation();
                                }}
                              >
                                <Iconify
                                  icon="material-symbols:edit"
                                  onClick={(event) => {
                                    getUserDetails(userId);
                                    event.stopPropagation();
                                  }}
                                />
                              </BorderColorIcon>
                            </Tooltip>

                            <Tooltip title="View logs">
                              <Button
                                type="submit"
                                onClick={() =>
                                  navigate(`/dashboard/view-logs?type=user&id=${userId}`, {
                                    state: {
                                      type: 'name',
                                      name: firstName + ' ' + lastName,
                                    },
                                  })
                                }
                                style={{
                                  width: 50,
                                  minWidth: '50px',
                                  fontSize: '12px',
                                  padding: '6px',
                                  marginLeft: '8px',
                                }}
                              >
                                <HistoryIcon />
                              </Button>
                            </Tooltip>
                          </TableCell>

                          {selectedTabValue === '4' && (
                            <TableCell align="left" style={{ padding: '6px' }}>
                              <Tooltip title="Send activation email">
                                <Button
                                  type="submit"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  variant="contained"
                                  onClick={() => {
                                    setisSendLink({
                                      row,
                                      show: true,
                                    });
                                  }}
                                  style={{
                                    width: 50,
                                    minWidth: '50px',
                                    fontSize: '12px',
                                    padding: '6px',
                                    marginLeft: '8px',
                                  }}
                                >
                                  <EmailIcon />
                                </Button>
                              </Tooltip>
                            </TableCell>
                          )}
                        </TableRow>
                      ) : (
                        <PermanentDeactivatedUser
                          userId={userId}
                          row={row}
                          firstName={firstName}
                          lastName={lastName}
                          getUserDetails={getUserDetails}
                          createdDate={createdDate}
                          notes={notes}
                          getUserDetailsInfo={getUserDetailsInfo}
                          setaccidentalReactivate={setaccidentalReactivate}
                          navigate={navigate}
                        />
                      )}
                    </>
                  );
                })) ||
                ''}

              <Modal
                open={isOpenModal.sandEmail || false}
                onClose={() => {
                  setIsOpenModal({ sandEmail: false });
                  reset({ emailTemplate: {} });
                  setTemplateData(() => ({
                    content: '<p></p>',
                    Subject: '',
                    templateFromName: '',
                  }));
                  setPersonName([]);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{ width: '59%' }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Send Email
                  </Typography>

                  <form onSubmit={(e) => handleSendEmail(e)}>
                    <div
                      className="container"
                      style={{
                        overflow: 'auto',
                        height: '450px',
                        width: '105%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          '& .MuiTextField-root': { width: '75ch' },
                        }}
                      >
                        <FormControl sx={{ mt: 3, mb: 1, width: 735 }}>
                          <InputLabel id="demo-simple-select-required-label">Select E-mail Template</InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            label="Select E-mail Template"
                            {...register('emailTemplate.selectedTemplate')}
                          >
                            {emailTemplate?.map((item) => {
                              let actieveTemplates = {};
                              const { templateName, id, templateActive } = item;
                              if (templateActive) {
                                actieveTemplates = { templateName, id, item };
                              }
                              return (
                                <MenuItem key={actieveTemplates.id} value={actieveTemplates.item}>
                                  {actieveTemplates?.templateName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <TextField
                          value={templateData.Subject || ''}
                          label={'Subject'}
                          {...register('emailTemplate.templateSubject', {
                            onChange: (e) => {
                              setTemplateData((prevState) => ({
                                ...prevState,
                                Subject: e.target.value,
                              }));
                            },
                          })}
                          id="Subject"
                          margin="normal"
                        />

                        <TextField
                          required
                          value={templateData.templateFromName || ''}
                          label={'Form Name'}
                          {...register('emailTemplate.templateFromName', {
                            onChange: (e) => {
                              setTemplateData((prevState) => ({
                                ...prevState,
                                templateFromName: e.target.value,
                              }));
                            },
                          })}
                          id="Form-Name"
                          margin="normal"
                        />

                        <FormControl sx={{ mt: 2, mb: 2, width: 735 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Send To</InputLabel>
                          <Select
                            multiple
                            value={personName}
                            MenuProps={MenuProps}
                            onChange={handleChange}
                            id="demo-multiple-checkbox"
                            labelId="demo-multiple-checkbox-label"
                            input={<OutlinedInput label="Send To" {...register('emailTemplate.templateReplyTo')} />}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected?.map((value) => (
                                  <Chip key={value} label={value} />
                                ))}
                              </Box>
                            )}
                          >
                            {filteredUsers?.map((items, index) => {
                              const { email } = items;
                              return (
                                <MenuItem key={index} value={email}>
                                  <Checkbox checked={personName.indexOf(email) > -1} />
                                  <ListItemText primary={email} />
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <CKEditor
                          editor={ClassicEditor}
                          data={watch('emailTemplate.selectedTemplate')?.html || '<p></p>'}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setTemplateData((prevState) => ({
                              ...prevState,
                              content: data,
                            }));
                          }}
                          config={{
                            // extraPlugins: [
                            //   uploadPlugin,
                            //   // Image,
                            //   // ImageResizeEditing,
                            //   // ImageResizeHandles /* ... */,
                            // ],
                            mediaEmbed: {
                              previewsInData: true,
                            },
                            removePlugins: [
                              'Image',
                              'ImageEditing ',
                              'CKFinderEditing',
                              'CKFinder',
                              'ImageCaption',
                              'ImageStyle',
                              'ImageToolbar',
                              'ImageUpload',
                              'EasyImage',
                            ],
                          }}
                        />
                      </Box>
                    </div>

                    <div className="template-buttons">
                      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Send To</InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={emailType}
                          label="Send To"
                          onChange={handleEmailType}
                        >
                          <MenuItem disabled value="">
                            <em>Send To</em>
                          </MenuItem>
                          <MenuItem value={1}>Send to All Active user</MenuItem>
                          <MenuItem value={2}>Send to All Inactive user</MenuItem>
                          <MenuItem value={3}>Send to All Passive user</MenuItem>
                        </Select>
                      </FormControl>

                      <Button
                        variant="outlined"
                        onClick={() => {
                          reset({ emailTemplate: {} });
                          setIsOpenModal({ sandEmail: false });
                          setTemplateData(() => ({
                            content: '<p></p>',
                            Subject: '',
                            templateFromName: '',
                          }));
                          setPersonName([]);
                        }}
                      >
                        Cancel
                      </Button>

                      <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
                        Send
                      </Button>
                    </div>
                  </form>
                </Box>
              </Modal>

              <Modal
                open={activateModal.show}
                onClose={() => setActivateModal({ show: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={getModalBoxStyle()}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to {activateModal.isActive === 'true' ? 'deactivate' : 'activate'} this user?
                  </Typography>
                  <div
                    style={{
                      margin: '25px 0 0',
                      float: 'right',
                      gap: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={() => setActivateModal({ show: false })}
                      sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                      autoFocus
                    >
                      No
                    </Button>
                    <Button
                      onClick={() => {
                        activateOrDeactivateUser(
                          activateModal.userId,
                          activateModal.isActive === 'true' ? 'false' : 'true',
                          activateModal?.row
                        );
                      }}
                      sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                      autoFocus
                    >
                      Yes
                    </Button>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={permanentDeactivate.show}
                onClose={() => {
                  setpermanentDeactivate({ show: false });
                  setisReasonEmpty(false);
                  setreasonForDeactivated('');
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={getModalBoxStyle()}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to permanent deactivate this user?
                  </Typography>
                  <div
                    style={{
                      margin: '25px 0 0',
                      gap: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <form onSubmit={(e) => handlePermanentDeactivateUser(e, permanentDeactivate?.userId)}>
                      <Form.Group className="modalform" style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Control
                          as="textarea"
                          placeholder="Reason for permanent deactivating this user"
                          rows={3}
                          style={{
                            resize: 'none',
                            width: '100%',
                            borderRadius: '12px',
                            borderColor: reasonForDeactivated.trim() === '' && isReasonEmpty && '#eb5e5e',
                          }}
                          onChange={(e) => setreasonForDeactivated(e.target.value)}
                          value={reasonForDeactivated}
                        />
                      </Form.Group>
                      <Form.Text
                        style={{
                          fontSize: '14px',
                          color: '#eb5e5e',
                        }}
                      >
                        {reasonForDeactivated.trim() === '' &&
                          isReasonEmpty &&
                          'Please fill in the reason for deactivating this user'}
                      </Form.Text>
                      <div className="mt-2">
                        <Button type="submit" sx={{ float: 'right', backgroundColor: '#62b5e514' }} autoFocus>
                          Yes
                        </Button>

                        <Button
                          onClick={() => {
                            setpermanentDeactivate({ show: false });
                            setisReasonEmpty(false);
                            setreasonForDeactivated('');
                          }}
                          sx={{
                            float: 'right',
                            marginRight: '15px',
                            backgroundColor: '#62b5e514',
                          }}
                          autoFocus
                        >
                          No
                        </Button>
                      </div>
                    </form>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={accidentalReactivate.show}
                onClose={() => {
                  setaccidentalReactivate({ show: false });
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={getModalBoxStyle()}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Are you sure you want to re-activate this user?
                  </Typography>
                  <div className="mt-4">
                    <Button
                      onClick={handleChangeStatusOfDeactivate}
                      type="submit"
                      sx={{ float: 'right', backgroundColor: '#62b5e514' }}
                      autoFocus
                    >
                      Yes
                    </Button>
                    <Button
                      onClick={() => {
                        setaccidentalReactivate({ show: false });
                      }}
                      sx={{
                        float: 'right',
                        marginRight: '15px',
                        backgroundColor: '#62b5e514',
                      }}
                      autoFocus
                    >
                      No
                    </Button>
                  </div>
                </Box>
              </Modal>
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={searchQuery} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Modal
        open={isOpenModal.bankDetails || false}
        onClose={() => setIsOpenModal({ bankDetails: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Bank Details
          </Typography>
          <form
            onSubmit={handleSubmitForm2((values) => {
              editBankDetails(values.bankDetails);
            })}
          >
            <div className="edit-bank-details">
              <TableRow>
                <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold', mr: 1 }}>
                  Banks
                </TableCell>
                <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                  <Select
                    sx={{ mt: 2, mb: 2, width: '100%' }}
                    variant="standard"
                    id="chooseBankName"
                    value={chooseBankName || ''}
                    label="Bank"
                    {...registerForm2('bankDetails.chooseBankName')}
                    onChange={handleBankChange}
                  >
                    {banksData &&
                      banksData?.length > 0 &&
                      banksData?.map((bankData, index) => (
                        <MenuItem key={index} value={bankData.description}>
                          {bankData.description}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
              </TableRow>

              {chooseBankName === 'Foreign bank BIC/IBAN' || chooseBankName === 'Foreign bank BIC / IBAN' ? (
                <>
                  <TableRow>
                    <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                      IBAN Number
                    </TableCell>
                    <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                      <TextField
                        id="ibanNumber"
                        {...registerForm2('bankDetails.ibanNumber', {
                          required: true,
                        })}
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                      BIC Number
                    </TableCell>
                    <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                      <TextField
                        id="bicNumber"
                        {...registerForm2('bankDetails.bicNumber', {
                          required: true,
                        })}
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                </>
              ) : null}

              {!(chooseBankName === 'Foreign bank BIC / IBAN' || chooseBankName === 'Foreign bank BIC/IBAN') ? (
                <>
                  {chooseBankName === 'Other banks' && (
                    <TableRow>
                      <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                        Bank Name
                      </TableCell>
                      <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                        <TextField
                          id="createBankName"
                          {...registerForm2('bankDetails.createBankName')}
                          variant="standard"
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                      Clearing Number
                    </TableCell>
                    <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                      <TextField
                        id="clearingNumber"
                        {...registerForm2('bankDetails.clearingNumber')}
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                      Account number
                    </TableCell>
                    <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                      <TextField
                        id="accountNumber"
                        {...registerForm2('bankDetails.accountNumber')}
                        variant="standard"
                      />
                    </TableCell>
                  </TableRow>

                  {Object.keys(selectedUser)?.length > 0 && selectedUser?.customerSalaryInformation?.length > 0 && (
                    <TableRow>
                      <TableCell align="left" colSpan={6} sx={{ py: 3, fontWeight: 'bold' }}>
                        Created Date
                      </TableCell>
                      <TableCell align="left" colSpan={6} sx={{ py: 3 }}>
                        {selectedUser?.customerSalaryInformation[0]?.createdDate
                          ? new Date(selectedUser.customerSalaryInformation[0]?.createdDate).toISOString().split('T')[0]
                          : ''}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ) : null}
            </div>

            <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
              Save Changes
            </Button>

            <Button
              onClick={() => {
                resetForm2({ bankDetails: {} });
                setBankName(
                  Object.keys(selectedUser)?.length > 0 ? selectedUser.customerSalaryInformation[0]?.bank : ''
                );
                setChooseBankName(banksData.some((item) => item.description === bankName) ? bankName : 'Other banks');
                setIsOpenModal({ bankDetails: false });
              }}
              sx={{ float: 'right' }}
              autoFocus
            >
              Close
            </Button>
          </form>
        </Box>
      </Modal>

      {/* Add Notes  Modal */}
      <Modal
        open={toggleNote}
        onClose={() => {
          setToggleNote(false);
          setNotesContent('');
          setIsNotesContentEmpty(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={commentStyle}>
          <Box sx={{ height: 'fit-content' }} id="usersList-modal">
            <IconButton
              color="inherit"
              onClick={() => {
                setToggleNote(false);
                setNotesContent('');
                setIsNotesContentEmpty(false);
              }}
              sx={{
                position: 'absolute',
                right: 5,
                top: 0,
              }}
              aria-label="close"
            >
              <ClearIcon style={{ fontSize: 26 }} />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: '95%' }}>
              Notes
            </Typography>
            <Form>
              <Form.Group
                className="modalform"
                style={{
                  marginTop: '20px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{
                    resize: 'none',
                    width: '50%',
                    borderRadius: '12px',
                    borderColor: isNotesContentEmpty && '#eb5e5e',
                  }}
                  onChange={(e) => setNotesContent(e.target.value)}
                  value={notesContent}
                />
                <Button variant="contained" style={{ height: '40px', marginLeft: '20px' }} onClick={postAddnotes}>
                  <AddCircleIcon />
                </Button>
              </Form.Group>
            </Form>
            <div style={{ height: '250px', overflow: 'auto', marginTop: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date </TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Added By</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  ''
                ) : (
                  <TableBody>
                    {commentList?.length > 0 ? (
                      commentList?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {formatDate(row?.createdDate)}</TableCell>
                          <TableCell>
                            {row?.description?.length > 30 ? (
                              <TooltipWithCopy text={row?.description} />
                            ) : (
                              row?.description
                            )}
                          </TableCell>
                          <TableCell> {row?.addedBy} </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className="text-center">
                        <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </div>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={showEditModal || false}
        onClose={() => {
          setShowEditModal(false);
          setShowUserDetails(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit User Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form
              onSubmit={handleSubmit((values) => {
                editUserDetails(values);
              })}
            >
              <div className="container" style={{ overflow: 'auto', height: '450px', width: '105%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    marginTop: '9px',
                    flexDirection: 'column',
                    '& .MuiTextField-root': { width: '25ch' },
                  }}
                >
                  <Autocomplete
                    freeSolo
                    value={watch('userAccountType')}
                    id="combo-box-demo"
                    options={[
                      { label: 'Private Account' },
                      { label: 'Business Account' },
                      // { label: 'Business Construction' },
                    ]}
                    sx={{ width: 300, marginTop: '12px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ marginBottom: '10px' }}
                        label={'Account Type'}
                        id="margin-dense"
                        {...register('userAccountType', {
                          required: true,
                        })}
                        onKeyDown={(e) => {
                          const inputValue = e.key;
                          const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                          if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  />

                  <TextField
                    error={errors.firstName}
                    label={'First Name'}
                    {...register('firstName', {
                      required: true,
                    })}
                    id="margin-dense"
                  />
                  <TextField label={'Last Name'} {...register('lastName')} id="margin-normal" margin="normal" />
                  <TextField
                    type="email"
                    error={errors?.email}
                    label={'Email'}
                    {...register('email', {
                      required: true,
                    })}
                    id="margin-dense"
                    margin="normal"
                  />
                  <TextField label={'Address'} {...register('address')} id="margin-normal" margin="normal" />
                  <TextField
                    label={'City'}
                    {...register('city')}
                    id="margin-dense"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      // This regular expression allows any character except digits (0-9)
                      const disallowedCharacters = /^[0-9]$/;

                      if (inputValue !== 'Backspace' && disallowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField
                    label={'Profession'}
                    {...register('profession')}
                    id="margin-normal"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      // This regular expression allows any character except digits (0-9)
                      const disallowedCharacters = /^[0-9]$/;

                      if (inputValue !== 'Backspace' && disallowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <Autocomplete
                    freeSolo
                    value={watch('country')}
                    id="combo-box-demo"
                    options={newDetails}
                    sx={{ width: 300, marginTop: '12px' }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errors?.country}
                        {...register('country', {
                          required: true,
                        })}
                        label="Country"
                      />
                    )}
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      // This regular expression allows any character except digits (0-9)
                      const disallowedCharacters = /^[0-9]$/;
                      if (inputValue !== 'Backspace' && disallowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />

                  <FormControl className="mt-2" sx={{ width: 248, marginTop: '12px' }}>
                    <InputLabel id="demo-simple-select-label">DOB/SSN</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedOption}
                      onChange={handleSelectChange}
                      label="DOB/SSN"
                    >
                      <MenuItem value="DOB">DOB</MenuItem>
                      <MenuItem value="SSN">SSN</MenuItem>
                    </Select>
                  </FormControl>

                  {selectedOption === 'SSN' ? (
                    <>
                      <TextField
                        error={errors?.ssn}
                        label={'SSN No.'}
                        {...register('ssn', {
                          required: true,
                          minLength: 12,
                        })}
                        id="margin-dense"
                        margin="normal"
                        onKeyDown={(e) => {
                          const inputValue = e.key;
                          const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                          if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                            e.preventDefault();
                          }
                        }}
                        onInput={(e) => {
                          const maxLength = 12;
                          e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');

                          if (e.target.value.length > maxLength) {
                            e.target.value = e.target.value.slice(0, maxLength);
                          }
                        }}
                      />
                      {ssnNumber?.length < 12 ? (
                        <p
                          style={{
                            fontSize: '11px',
                            color: 'red',
                            padding: '0 14px',
                          }}
                        >
                          SSN number must have 12 digits
                        </p>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <TextField
                      label={'DOB'}
                      error={errors?.dateOfBirth}
                      {...register('dateOfBirth', {
                        required: true,
                      })}
                      id="margin-dense"
                      margin="normal"
                      onKeyDown={(e) => {
                        const inputValue = e.key;
                        const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                        if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                  <TextField
                    label={'Phone No.'}
                    error={errors?.phoneNo}
                    {...register('phoneNo', {
                      required: true,
                    })}
                    id="margin-normal"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                      if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField
                    label={'Postal Code'}
                    {...register('zipCode')}
                    id="margin-dense"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                      if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField label={'C/O'} {...register('organisation')} id="margin-normal" margin="normal" />
                  <TextField
                    label={'Income Tax'}
                    {...register('incomeTax')}
                    id="margin-normal"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                      if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField
                    label={'Invozio Fee'}
                    {...register('fakturaFee')}
                    id="margin-dense"
                    margin="normal"
                    onKeyDown={(e) => {
                      const inputValue = e.key;
                      const allowedCharacters = /^[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]$/;

                      if (inputValue !== 'Backspace' && !allowedCharacters.test(inputValue)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Box>
              </div>

              <Button
                variant="contained"
                onClick={() => {
                  setShowEditModal(false);
                  setShowUserDetails(null);
                }}
              >
                Close
              </Button>
              <Button style={{ marginLeft: '10px' }} variant="contained" type="submit">
                Save Changes
              </Button>
            </form>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={isSendLink.show}
        onClose={() => setisSendLink({ show: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalBoxStyle()}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {'Are you sure you want to resend account activation email?'}
          </Typography>
          <div
            style={{
              margin: '25px 0 0',
              float: 'right',
              gap: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setisSendLink({ show: false })}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleSendLink(isSendLink);
              }}
              sx={{ float: 'right', backgroundColor: '#62b5e514' }}
              autoFocus
            >
              Yes
            </Button>
          </div>
        </Box>
      </Modal>

      {/* get Deactivated data */}

      <Modal
        open={toggleuserInfo}
        onClose={() => {
          settoggleuserInfo(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={commentStyle}>
          <Box sx={{ height: 'fit-content' }} id="usersList-modal">
            <IconButton
              color="inherit"
              onClick={() => {
                settoggleuserInfo(false);
              }}
              sx={{
                position: 'absolute',
                right: 5,
                top: 0,
              }}
              aria-label="close"
            >
              <ClearIcon style={{ fontSize: 26 }} />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2" style={{ width: '95%' }}>
              Deactivated Notes
            </Typography>

            <div style={{ height: '250px', overflow: 'auto', marginTop: '10px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date </TableCell>
                    <TableCell>Message</TableCell>
                    <TableCell>Added By</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  ''
                ) : (
                  <TableBody>
                    {getDeactivateduserInfo?.length > 0 ? (
                      getDeactivateduserInfo?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell> {formatDate(row?.createdDate)}</TableCell>
                          <TableCell>
                            {row?.description?.length > 30 ? (
                              <TooltipWithCopy text={row?.description} />
                            ) : (
                              row?.description
                            )}
                          </TableCell>
                          <TableCell> {row?.addedBy} </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow className="text-center">
                        <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </div>
          </Box>
        </Box>
      </Modal>

      <HoursModal
        getUserId={getUserId}
        downloadData={downloadData}
        setincomeData={setincomeData}
        setSelectedYear={setSelectedYear}
        years={years}
        incomeData={incomeData}
        toggleHourModal={toggleHourModal}
        settoggleHourModal={settoggleHourModal}
        currentYear={currentYear}
      />

      <MultipleUsersPDF openPDFModal={openPDFModal} setOpenPDFModal={setOpenPDFModal} selectedUserId={selected} />

      <TablePagination
        rowsPerPageOptions={[100, 250, 500]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );

  const handleTabChange = (e, newValue) => {
    setSearchQuery('');
    setPage(0);
    setSelected([]);
    setSelectedTabValue(newValue);
    setAllSelected(false);
  };

  return (
    <Page title="User">
      {(loading || isLoading) && <Loader />}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction="row" justifyContent="space-between">
            <IconButton onClick={() => navigate(-1)}>
              <Iconify icon="eva:arrow-back-fill" width={24} height={24} />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
              Users
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            {selected && selected?.length > 0 && (
              <Tooltip arrow title={'Print'}>
                <Button style={{ marginLeft: '10px' }} variant="contained" onClick={() => setOpenPDFModal(true)}>
                  Print
                </Button>
              </Tooltip>
            )}

            <Tooltip arrow title={'Send Email'}>
              <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                onClick={() => setIsOpenModal({ sandEmail: true })}
              >
                Send Email
              </Button>
            </Tooltip>
          </Stack>
        </Stack>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isSnackBarOpen}
          autoHideDuration={autoHideDuration}
          onClose={handleSnackBarClose}
        >
          <Alert onClose={handleSnackBarClose} severity={alertStatus} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Card>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={selectedTabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={(e, newValue) => handleTabChange(e, newValue)} aria-label="lab API tabs example">
                  <Tab label="ACTIVE" value="2" />
                  <Tab label="INACTIVE" value="3" />
                  <Tab label="ALL" value="1" />
                  <Tab label="PASSIVE" value="4" />
                  <Tab label="PERMANENT DEACTIVATED USERS" value="5" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ padding: 0 }}>
                {renderUsers()}
              </TabPanel>
              <TabPanel value="2" sx={{ padding: 0 }}>
                {renderUsers()}
              </TabPanel>
              <TabPanel value="3" sx={{ padding: 0 }}>
                {renderUsers()}
              </TabPanel>
              <TabPanel value="4" sx={{ padding: 0 }}>
                {renderUsers()}
              </TabPanel>
              <TabPanel value="5" sx={{ padding: 0 }}>
                {renderUsers()}
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
